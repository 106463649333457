@use '@compliance.ai/styles/dist/variables';

.crawler-history {
  &_element {
    padding: variables.$spacing-s variables.$spacing-xxs;
    border-bottom: variables.$border-width-xs solid variables.$primary-color-4;
  }

  &_field {
    display: flex;
    gap: variables.$spacing-xs;
  }
}
