@use '@compliance.ai/styles/dist/variables';

.eitl-label-delete-modal {
  &__container {
    width: 30vw;
  }

  &__content {
    padding: variables.$spacing-l;
  }

  &__message-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__content-container {
    position: relative;
  }
}
