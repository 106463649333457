.expandable-list {

  &_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_item {
    position: relative;

    &_disabled {
      svg {
        display: none;
      }
    }

    &_close-icon {
      position: absolute;
      right: 7px;
      bottom: 16px;
      cursor: pointer;

      &_active {
        color: $cerise;

        &:hover {
          color: $bright-red;
        }
      }

      &_disabled {
        color: $gainsboro;
      }
    }
  }

}
