.loading-mask {
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 1);
  background-attachment: scroll;
  top: 0;
  left: 0;
  z-index: 1005;
  position: absolute;

  i {
    top: 50%;
    left: 50%;
    margin-top: -16px;
    margin-left: -16px;
    font-size: 32px;
    position: fixed;
  }
}

.breadcrumb {
  button {
    margin-top: -2px;
    margin-left: 8px;
  }

  li + li:before {
    padding: 0 0 0 8px;
  }
}

.main-container {
  height: calc(100vh - 42px);
  overflow-y: auto;
  overflow-x: hidden;
}

.agencies-chart svg {
  height: 600px;
  width: 100%;

  .tick line {
    stroke-opacity: 0;
  }

  .nv-axis .zero line {
    stroke: black;
    stroke-opacity: 1;
  }

  .nvd3.nv-pie .nv-pie-title {
    fill: black;
  }

  .title-line-two {
    font-size: 14px;
  }
}

.text-container {
  height: 350px;
  overflow: auto;
  border-radius: 6px;
  background-color: #e7e9ea;
  border: solid 1px #e7e9ea;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.annotation-tool-submit-btn {
  margin-left: 30px;
}

.topic-form-group {
  margin-top: 20px;
  height: 80px;
}

.notes-text-area {
  margin-top: 10px;
  margin-bottom: 20px;
}

.annotation-tasks-container {
  border: solid 1px #e7e9ea;
  border-radius: 6px;
  padding: 10px;
}

.topic-definition {
  margin-bottom: 10px;
  border-radius: 5px;
  background-color: #d9ebfc;
  border: solid 1px #d9ebfc;
  padding: 10px;
}

.selected-term-item {
  margin: 3px;
  padding: 2px;
  display: inline-block;
  border-radius: 5px;
  background: #e6e6e6;
}

.selected-group-terms-container {
  height: 250px;
  overflow: auto;
  border-radius: 6px;
  border: solid 1px #e7e9ea;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.add-term-button {
  margin-top: 5px;
}

.counter-container {
  border: solid 1px #e7e9ea;
  border-radius: 10px;
  text-align: center;
  background: #b8e2ef;
}

.is-positive {
  color: green;
}

.is-negative {
  color: red;
}

.annotation-date {
  width: 100px;
  border: 1px solid #e7e9ea;
  border-radius: 5px;
  font-size: small;
  margin-bottom: 2px;
}

.volume-statistics-date-container {
  margin-top: 20px;
}

.volume-statistics-date-picker {
  width: 150px;
  border: 1px solid #e7e9ea;
  border-radius: 5px;
}

.volume-stat-radio-btn {
  margin-bottom: 15px;
}

.contributorModal {
  .modal-content {
    border-radius: 63px;

    .modal-body {
      position: relative;
      padding: 32px;
    }
  }

  .modal-footer {
    text-align: right;
    margin-left: 32px;
    border-top: 0px;
    border-bottom: 1px solid #e5e5e5;
    margin-bottom: 47px;
    margin-right: 36px;
    padding-top: 0px;
    padding-right: 1px;
  }
}

.contributor-document-container {
  height: 450px;
  overflow: auto;
  border-radius: 6px;
  border: solid 1px #e7e9ea;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.contributor-flagged-field {
  cursor: pointer;
}

.contributor-review-date {
  width: 100px;
  border: 1px solid #e7e9ea;
  border-radius: 5px;
  margin: 5px;
}

.contributor-statistics-csv-link {
  margin-top: 10px;
  margin-bottom: 10px;
}

.flagged {
  color: red;
}

.unflagged {
  color: #d1d1d1;
}

.contributor-buttons {
  float: right;
}

.contributor-view-checkbox {
  float: left;
}

.back-to-product {
  margin-left: 20px;
}

.indented-text {
  text-indent: 2em;
}

.indented-text-double {
  text-indent: 4em;
}

.instruction-header {
  margin-bottom: 20px;
  margin-top: 20px;
}

.text-large {
  font-size: large;
}

.instructions-paragraph {
  margin-top: 20px;
}

.subscription-header {
  font-size: small;
}

.subscription-date {
  width: 100px;
  margin-bottom: 10px;
}

#pdf-overlay,
#doc-overlay {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: 1000;
  overflow: hidden;
  background-color: $white;

  iframe {
    width: 100%;
    height: 100%;
  }
}

#doc-overlay {
  overflow-y: auto;
  overflow-x: hidden;

  .document_html-error-container {
    text-align: center;
    @extend .bg-danger;
    position: absolute;
    z-index: 1000;
    width: 95%;
    top: 10px;
    cursor: pointer;
    margin: 0 20px;

    span {
      display: block;
      margin-top: 10px;
    }
  }
}

.spinnerWrapper {
  position: fixed;
  opacity: 0.8;
  top: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  z-index: 6;

  .spinner {
    width: 150px;
    height: 20000px;
  }
}

.errorFormGroup {
  input {
    border-color: red;
  }

  .Select-control {
    border-color: red;
  }
}

.errorFormControl {
  border-color: red;
}

.jurispect-MuiTooltip-popper {
  .jurispect-MuiTooltip-tooltip {
    font-size: 12px;
  }
}

// ---- this common styles

.document-meta-table,
.document-table {
  .commandRequests {
    &_footer {
      display: flex;
      position: relative;
      justify-content: center;
      align-items: center;

      .pagination {
        display: flex;
      }

      .limitWrapper {
        position: absolute;
        right: 2%;
        display: flex;
        align-items: center;

        .limitInput {
          width: 60px;
          margin: 0 10px;
        }
      }
    }

    &_filter {
      display: flex;

      &_select {
        flex: 1;
      }
    }
  }
}

.docMetaModal {
  &_dropableArea {
    min-height: 120px;
    border-color: #d9d9d9 #ccc #b3b3b3;
    border-radius: 4px;
    border: 1px solid #ccc;
    list-style: none;
    padding: 5px;
  }

  &_dragablElem {
    display: inline-block;
    height: 22px;
    min-width: 45px;
    color: #fff;
    background-color: #65b0c9;
    border-color: #52a6c2;
    border-radius: 4px;
    margin: 3px;
    padding: 2px 5px;
    text-align: center;
  }

  hr {
    border-color: #65b0c9;
  }

  .centerText {
    text-align: center;

    label {
      padding: 5px;
      margin: 0;
    }
  }

  .errorAlert {
    margin: 15px 0 0;
  }

  .columnWithoutLeftPadding {
    padding-left: 0;
  }

  .shortedInfoText {
    width: 450px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all 1s;
  }

  .shortedInfoText:hover {
    overflow: inherit;
    text-overflow: inherit;
    white-space: inherit;
    height: auto;
  }
}

.filterBarWrapper_elem {
  z-index: 5;
}

.boldNotification {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &_Text {
    font-size: 24px;
    margin: 10px 10px 10px 0;
  }
}

.centeredWrapperPopup {
  display: flex;
  justify-content: space-around;
  align-items: center;

  & + .alert {
    margin-top: 10px;
    margin-bottom: 0;
  }

  .inputWrapperForUpload {
    position: relative;
    width: 100%;
    height: 150px;
    border-radius: 10px;
    border: 1px solid #65b0c9;
    background-image: url('~shared/images/misc/backgroundDropArea.png');
    background-position: 50%;
    background-repeat: no-repeat;
    overflow: hidden;

    input {
      width: 100%;
      height: 0;
      padding-bottom: 170px;
      outline: none;
      vertical-align: middle;
      cursor: pointer;
      margin-top: -22px;
      transition: background-color ease-out 0.5s;
      opacity: 0.3;

      &:hover {
        opacity: 0.3;
        background-color: white;
      }
    }
  }

  .uploadImageInfo {
    padding: 10px;
    background: white;
    height: 100%;

    ul {
      margin-top: 20px;
      margin-left: 15px;
    }

    &_closeBtn {
      width: 20px;
      height: 20px;
      float: right;
      color: red;
      border-radius: 8px;
      text-align: center;
      cursor: pointer;
      transition: all 1s;

      &:hover {
        opacity: 0.5;
      }
    }
  }

  .uploadTSVsettings {
    background-image: url('~shared/images/misc/cog.png');
    width: 40px;
    height: 40px;
    background-size: 40px;
    position: absolute;
    left: 10px;
    bottom: 10px;
    z-index: 2;
    transition: all 1s;

    &:hover {
      opacity: 0.5;
    }
  }

  .settingsTable {
    border: 1px solid #65b0c9;
    border-radius: 10px;
    margin-right: 10px;
    padding: 10px 10px 10px 32px;
    height: 150px;

    &.withoutFile {
      width: 100%;
    }

    &.withFile {
      margin-right: 10px;
      width: 45%;

      input {
        width: 70px;
      }
    }

    input {
      margin-left: 15px;
      text-align: center;
      border: none;
      outline: none;
      border-bottom: 1px solid #65b0c9;
    }

    @media (max-width: 580px) {
      width: 100%;
      margin-right: 0;
    }
  }

  @media (max-width: 580px) {
    flex-direction: column;
  }
}

.commandErrorLog {
  max-height: 350px;
}

.sectionHead {
  text-align: center;
}

.checkboxForm {
  margin: 0;
}

.btn-danger.closeBtn {
  height: 35px;
  width: 35px;
  min-width: 30px;
}

.filterSpace {
  margin-top: 5px;
  margin-bottom: 5px;
}

.listOfInfoInTable {
  padding-left: 12px;
}
