.modalBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $shadow;
  z-index: 100;

  &_block {
    display: block;
  }

  &_hide {
    display: none;
  }

  .modal {
    overflow: initial;
    position: fixed;
    background: $white;
    height: auto;
    max-height: 80%;
    top: 50%;
    left: 50%;
    bottom: unset;
    transform: translate(-50%, -60%);
    display: block;
    z-index: 110;

    &_small {
      width: 35%;
    }

    &_medium {
      width: 70%;
    }

    &_large {
      width: 90%;
    }

    header {
      display: flex;
      justify-content: space-between;
      background-color: $white-smoke;
      box-shadow: 0 1px 0 0 $gainsboro;
      align-items: center;

      .modal-title {
        margin: 14px;
      }

      .modal-right {
        display: flex;
        align-items: center;

        &_child {
          padding: 10px;
        }
      }

      .modal-close {
        padding: 15px 20px;
        line-height: 0.5;
        border-left: 1px solid $gainsboro;
        cursor: pointer;
      }

    }

    & > div {
      overflow: scroll;
      max-height: 70vh;
    }

    .custom-table {
      &_content-wrapper {
        display: block;
        max-width: 150px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      tr td:first-child {
        padding-left: 15px;
      }

      .custom-table_head {
        tr td:nth-child(8) {
          direction: rtl;
        }
      }
    }

  }


}
