.all_organizations_view_page {
  .all_org_table_td {
    width: 43%;

  }
  &.allorg-team-id {
    padding-top: 62px !important;
    p {
      padding-bottom: 0px !important;
    }
  }
}

.single-org-container {
  .organizations-detail-table {
    background-color: $gray-lighter !important;
    tr {
      background-color: $gray-lighter !important;
      td {
        width: 49%;
        padding-left: 25px !important;
        padding-bottom: 14px;
        h1 {
          margin-bottom: 17px;
          width: 80%;
        }
        .createOrgButtons {
          display: flex;
        }
        .org-btn-cancel {
          width: 77px;
          padding: 8px;
          margin-right: 40px;
          background-color: $white;
          border: none;
          color: $black;
        }
        .org-btn-create {
          width: 77px;
          padding: 8px;
          margin-right: 10px;
          background-color: $powder-blue;
          border: none;
          color: $white;
        }
        .actionButtons {
          display: flex;

          width: 80%;
          height: 100%;
          margin-bottom: 43px;
          span {
            b {
              float: left;
              &.with-input {
                padding-top: 5px;
              }
            }
            input {
              width: 80%;
              float: right;
            }
          }
          .org-team-id-show {
            margin-bottom: 121px;
          }

          .org-btn-create,
          .org-btn-edit {
            width: 77px;
            padding: 8px;
            margin-right: 10px;
            background-color: $powder-blue;
            border: none;
            color: $white;
          }
        }
        input {
          border: 0px solid $white;
          border-radius: 0;
          font-size: 16px;
          font-weight: 400;
        }
      }
    }
  }
  .org-members-Invite-div {
    tbody {
      tr {
        td {
          padding-left: 25px;
          padding-top: 20px;
          .addUsersToOrgSection {
            display: flex;
            flex-direction: column;
            float: right;

            .addUserToOrgButton {
              padding: 11px;
              color: $white;
              background-color: $powder-blue;
              border: none;
              float: right;
              width: 176px;
              margin-bottom: 10px;
            }

            .selectUserBox, .selectRoleBox {
              max-width: 200px;
              margin-bottom: 10px;

              .Select {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
  .org-member-list {
    width: 100%;
    background-color: #ebebeb;
    height: 67px;
    margin-top: -20px;
    margin-bottom: 20px;
    .table {
      background-color: #dadad8;
      height: 34px;
      thead {
        tr {
          th {
            padding-left: 26px;
          }
        }
      }
      tbody {
        tr {
          td {
            padding-left: 26px;
          }
          .pendingData {
            padding-right: 20px;
            .pending {
              background-color: #ada6a6;
              width: 140px;
              height: 20px;
              text-align: center;
              float: right;
            }
          }
          .delete {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.invite-admin-org-modal {
  &.modal-dialog {
    position: absolute;
    right: 0;
    width: 430px;
    height: 190px;
    .modal-content {
      border: none;
    }
    .modal-body {
      padding: 0px;
      .invite-popup {
        width: 420px;
        position: absolute;
        top: 270px;
        right: 105px;
        height: 222px;
        padding-top: 25px;
        background-color: #000;
        input {
          width: 100%;
          border-radius: 0px;
          padding: 29px;
          font-size: 24px;
        }
        .Content {
          padding-top: 15px;
          color: #ffff;
          padding-left: 20px;
          .invite-user-area {
            width: 50%;
            p {
              float: left;
              padding: 10px;
            }
            .Select {
              width: 43%;
              float: left;
              .Select-control {
                border-radius: 0px;
              }
              .Select-control {
                position: unset;
              }
            }
          }
          .invite-btn {
            margin: 31px;
            padding: 8px;
            width: 43%;
            background-color: #1771da;
            color: #ffffff;
            border-radius: 0px;
          }
        }
      }
    }
  }
}
