@use '@compliance.ai/styles/dist/variables';

.сrawler-qa-reports-details-modal {
  &:not(.CAI-modal__inner-container--is-fullscreen) {
    width: 70vw;
    height: 80vh;
  }

  &__content-container {
    height: 100%;
  }
}
