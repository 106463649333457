.agencies {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  height: 86vh;
  .modalBackground .modal > div {
    overflow: scroll;
    max-height: 70vh;
  }
  .tableSettingModal_body {
    padding: 15px;
  }
  .tableSettingModal_contentWrapper {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid #b2b2b2;
  }
  .tableSettingModal_contentWrapper_chooseColumn {
    flex: 1;
    border-right: 1px solid #b2b2b2;
    padding: 20px;
    > div {
      margin: 7px 0;
    }
  }
  .tableSettingModal_contentWrapper_chooseColumnOrder {
    flex: 1;
    padding: 15px;
    .sortableColumnElement {
      width: 100%;
      padding: 10px;
      margin: 10px 0;
      background-color: #F0F0F0;
      border: 1px solid #b2b2b2;
      border-radius: 5px;
    }
  }
  .tableSettingModal_body .footerBtnBlock {
    display: flex;
    justify-content: flex-end;
    padding-top: 15px;
    align-items: center;
    button {
      margin-left: 15px;
    }
  }
}
.sortable_wrapper {
    display: flex;
    flex-direction: column;
}

.agency-table {
  height: 70vh;
  text-align: center;
  tr td:last-child {
    border-left: 1px solid #b2b2b2;
  }
  thead tr > td {
    cursor: pointer;
  }
  tbody > tr > td {
    cursor: pointer;
  }
  tbody > tr > td:last-child,
  thead tr > td:last-child {
    cursor: default;
  }
}
.agency_header-search {
  display: flex;
}
.agency_header_multi-input {
  width: 280px;
}
.agency_header {
  display: flex;
  justify-content: space-between;
  position: relative;
  > div button{
    margin-left: 15px;
    height: 38px;
  }
}
.agency-footer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
}
.limitWrapper {
  position: absolute;
  right: 2%;
  display: flex;
  align-items: center;
  .limitInput {
    width: 60px;
    margin: 0 10px;
  }
}
