.password-reset {
  &__input {
    width: 100%;
  }

  &__button {
    margin-top: 20px !important; // To overwrite MUI styles
    width: 100%;
  }
}
