@use '@compliance.ai/styles/dist/variables';

.eitl-labels-import-modal {
  &__dropzone-wrapper {
    padding: 2rem 0;
  }

  &__content {
    padding: variables.$spacing-l;
  }

  &__content-container {
    position: relative;
  }
}
