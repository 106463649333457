@use '@compliance.ai/styles/dist/variables';

.document-status {
  &__text {
    display: flex;
    align-items: center;
    gap: variables.$spacing-xs;
  }

  &__empty-value {
    color: variables.$secondary-color-10;
  }
}
