@use '@compliance.ai/styles/dist/variables';

.body-crawl-status {
  &__button.body-crawl-status__button {
    width: 100%;
    cursor: default;
    border-radius: variables.$border-radius-m;

    &--is-left-aligned > .MuiButton-label {
      text-align: left;
    }

    &--is-center-aligned > .MuiButton-label {
      text-align: center;
    }

    .MuiButton-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      display: block;
    }
  }
}
