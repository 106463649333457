.tableConfiguration-Modal {

  div.modal {
    top: 60%;
  }

  .bodyWrapper {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .bodyContent {
      display: flex;
      padding: 20px 20px 0;
      overflow: scroll;
      margin-bottom: 20px;

      .modalColumn {
        flex: 50%;
      }
    }

    .footerBtnBlock {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      button {
        margin-left: 15px;
        width: auto;
      }
    }
  }

  // Hack to solves issue with draggable item alignment in container with fixed position (for example in modal)
  .crawlMonitorDraggableField {
    top: auto !important;
    left: auto !important;
  }
}
