@use '@compliance.ai/styles/dist/variables';

.business_lines {
  &__container {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  &__filters {
    padding: variables.$spacing-m variables.$spacing-l;
    background-color: variables.$secondary-color-8;
    border-bottom: variables.$border-base;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: variables.$spacing-xs;
  }

  &__table-wrapper {
    flex: 1;
    position: relative;
  }

  &__table-outer-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
  }

  &__table-toolbar {
    padding: variables.$spacing-s variables.$spacing-l 0 variables.$spacing-l;
  }

  &__table-header-cell {
    white-space: nowrap;
  }

  &__table-inner-container {
    flex: 1;
    height: auto;
    position: relative;
  }

  &__table-selected-rows-count {
    margin-left: variables.$spacing-m;
  }
}
