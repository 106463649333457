@use '@compliance.ai/styles/dist/variables';

.export-dropdown {
  &__dropdown {
    padding: 0;
    width: 10rem;
  }

  &__container {
    display: flex;
    align-items: center;
  }

  &__label {
    padding: variables.$spacing-s;
    color: variables.$primary-color-4;
  }
}
