//$navy-blue: #112e61; // identity color (from branding guide)
//$powder-blue: #65B0C9; // accent color (from branding guide)
//$fern: #5cb85c;
//$chestnut-rose: #d9534f;
$notif-red: #ff3838;
$restrict-purple: #9013fe;
$black: #000;

$gray-border: #ddd;
$gray-text: #ccc;


// palette colors from style guide:
$app-bkgd: #e9f2f4;
$compliance_ai_bkgrd: #f7fafd;
$white: #fff;
$dark-grey: #4a4a4a;
$grey: #393939;
$mine-shaft: #333333;
$boulder: #777777;
$medium-grey: #9B9B9B;
$rando-grey: #898989;
$silver: #CCCCCC;
$gray: #828282;
$wild-sand: #f5f5f5;
$light-grey: #d8d8d8;
$code-grey: #f3f3f3;
$gainsboro: #DDDDDD;
$mercury: #E7E7E7;
$brand-blue: #112e61;
$hover-blue: #2C6DDF;
$hover-grey: #f3f3f3;
$azure-radiance: #078CFF;
$navy-blue-new: #068BFF;
$linkedin-blue: #0077b5;
$royal-blue: #4185DE;
$science-blue: #094FCC;
$brand-grey: #E5E5E5;
$right-panel-blue: #49628e;
$primary-action-blue: #7f91b6;
$faded-yellow: #ffecb3;
$active-grey: #F4F4F4;
$border-line-grey: #e7e9ea;
$bottom-border-grey: #D3D1D1;
$josue-grey: #dbdde8;
$josue-darker-grey: #cfcfcf;
$josue-blue: #2254d7;
$highlight-yellow: rgba(248, 231, 28, .75);
$highlight-green: rgba(107, 255, 90, 0.25);
$icon-purple: #8b1c41;
$icon-green: #278b1c;
$beta-orange: #ff7617;
$success-green: #2ea87f;
$feedback-green: #19a400;
$status-green: #D0E7E0;
$feedback-orange: #FFA929;
$danger-red: #E16768;
$logo-blue: #4D8DCC;
$black-grey: #272727;
$bar-grey: #f0f0f0;
$background-grey: #f2f2f2;
$month-blue: #c7e5ff;
$warning-purple: #8f00ee;
$pinkHighlight: #F4D0D4;
$limeHighlight: #DBF3C1;
$lime: #AFE47A;
$pink: #FA3F3D;




// FRB colors from style guide:
$frb-dark-green: #00340f;
$frb-background-green: #dcdfde;
$frb-yellow: #EAD484;

// insights color palette from style guide:
$insights-purple: #7c13a4;
$insights-light-blue: #0086d4;
$insights-teal: #00796b;
$insights-green: #348f37;
$insights-lime: #afb513;
$insights-yellow: #fcc102;
$insights-orange: #f77c00;

$insights-heatmap-high: #f19d83;
$insights-heatmap-medium: #f8d871;
$insights-heatmap-low: #d2d57d;

// other frb styles
$frb-light-green: #005518;
$frb-yellow-active-text: #EAD484;
$frb-background-grey: #DCDFDE;

$frb-dark-green-filters-text: #092E23;
$frb-item-inside-filter-bg: #F8F8F8;

// misc colors
$right-panel-inactive: #525252;
$docket: #7cc0fb;
$docket-timeline-line: #5d9bb8;



$bright-red: #ff0000;

$chartColor: #5591C0; //used for bar charts on agency landing page

:export {
  chartColor: $chartColor;
}
