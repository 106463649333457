@import "src/app/styles/modules/colors";

.radio-common-component {
  color: $mine-shaft;
  font-size: 14px;

  &--disabled {
    @extend .radio-common-component;
    color: $gray-text;

    input {
      opacity: 0.3;
    }

    &:hover {
      cursor: not-allowed;
      color: inherit;
    }
  }

  input {
    margin: 0;
  }
}
