.custom-tabs {

  &_list-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    background-color: $white-smoke;
    box-shadow: 0 1px 0 0 $gainsboro;
  }

  &_list {
    display: flex;
    padding-left: 0;
    margin-bottom: -1px;

    .custom-tab-list-item {
      display: flex;
      align-items: center;
      list-style: none;
      height: 100%;
      padding: 18px;
      opacity: 0.5;

      &:hover {
        cursor: pointer;
        opacity: 0.8
      }

      &_active {
        opacity: 1;
        border-bottom: 1px solid $royal-blue;
      }
    }
  }

  &_content-wrapper {

  }
}
