@use '@compliance.ai/styles/dist/variables';
@import 'src/admin/styles/modules/colors';

.business-lines-navigation {
  &_tabs {
    &_head {
      padding-left: variables.$spacing-m;
    }
  }

  &_status {
    padding: variables.$spacing-m;
    display: flex;
    justify-content: flex-end;

    &_date-block {
      padding: 0 variables.$spacing-l;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-around;
      border-right: variables.$border-base;

      & > span {
        display: block;
      }
    }

    &_status-block {
      padding: 0 variables.$spacing-l;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &_select-wrapper {
        min-width: variables.$spacing-m;
        margin-bottom: 0;

        &_select {
          .custom-select__control span {
            color: variables.$primary-color-2;
          }

          .custom-select__indicator-separator {
            display: block;
          }
          .custom-select__indicator {
            color: variables.$primary-color-2;
          }

          span {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;

            svg {
              width: variables.$spacing-l;
              height: variables.$spacing-l;
              margin-right: variables.$spacing-xxs;
            }
          }
        }

        .navigation-select {
          &_blue .custom-select__control {
            background-color: variables.$primary-color-5;
          }

          &_green .custom-select__control {
            background-color: variables.$secondary-color-3;
          }

          &_red .custom-select__control {
            background-color: variables.$secondary-color-5;
          }

          &_yellow .custom-select__control {
            background-color: variables.$secondary-color-4;
          }
        }
      }
    }

    &_icon-block {
      padding: 0 variables.$spacing-l;

      button {
        height: variables.$spacing-l;
        width: variables.$spacing-l;
        border-radius: variables.$spacing-semi-xs;
        background-color: variables.$primary-color-2;
        line-height: 0;

        svg {
          height: variables.$spacing-m;
          width: variables.$spacing-m;
          color: variables.$black;
        }
      }
    }
  }
}

.business-lines-details {
  display: flex;
  padding: variables.$spacing-m variables.$spacing-l;
  flex-flow: row wrap;

  &_body {
    display: flex;
    border-right: variables.$border-base-2;
    flex-direction: column;
    flex: 2;

    .general-info {
      border-bottom: variables.$border-base-2;
      padding-right: variables.$border-base-2;

      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;

        &_btn-block {
          display: flex;
          flex-direction: row;

          button {
            margin-left: variables.$spacing-s;
            padding: variables.$spacing-xs variables.$spacing-l;
          }
        }
      }

      &_body {
        display: flex;
        flex-flow: row wrap;
        gap: 2%;

        padding: variables.$spacing-l 0;

        & > label {
          // override default browser input settings
          min-width: 60px;
          margin-bottom: variables.$spacing-m;
        }

        // body block split to 8 parts

        .input_1 {
          flex: 1 0 11%;
        }
        .input_2 {
          flex: 1.8 0 23%;
        }
        .input_4 {
          flex: 3.8 0 48%;
        }
        .input_8 {
          flex: 8 0 100%;
        }
      }
    }
  }

  &_right-bar {
    flex: 1;
    padding-left: variables.$spacing-m;

    .additional-info {
      &_block {
        border-bottom: variables.$border-base-2;
        margin-bottom: variables.$spacing-m;

        & > label {
          margin-top: variables.$spacing-l;
          margin-bottom: variables.$spacing-m;
        }
      }
      &_logo-image {
        max-width: 300px;
        max-height: 90px;
      }
    }
  }
}
