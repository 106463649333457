.topic-judgment-container {
  .reset-link {
    padding-left: 5px;
  }

  .btn-topic-judgment-pdf {
    color: $white;
    width: 60%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 40px;
  }

  @media screen and (min-width: 600px) {
    .btn-topic-judgment-pdf {
      width: 60%;
    }
  }

  @media screen and (max-width: 600px) {
    .btn-topic-judgment-pdf {
      width: 100%;
    }
  }

  h1 {
    line-height: 29px;
    margin-bottom: 23px;
    font-family: Merriweather;
    font-weight: 700;
    display: block;
    min-height: 5em;
  }

  h2 {
    .embiggen {
      font-size: 24px;
      margin-left: 4px;
      vertical-align: baseline;
    }
  }

  .byline {
    font-family: $font-family-serif;
    font-size: 16px;
  }

  section {
    position: relative;
    width: 100%;

    p {
      font-family: $font-family-serif;
      font-size: 12px;
      line-height: 19px;
      text-align: justify;
      text-justify: auto;
      display: block;
    }

    .heading {
      text-align: center;
      font-family: 'serif';
      font-size: 16px;
      font-weight: 700;

      &.question {
        margin-bottom: 20px;
      }
    }

    .summary {
      font-family: $font-family-sans-serif;
      font-size: 14px;
      min-height: 150px;
      display: block;
    }

    .judgment-buttons-container {
      text-align: center;
      margin-bottom: 40px;

      div {
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
      }
      @media screen and (min-width: 600px) {
        button {
          width: 150px;
        }
      }
      @media screen and (max-width: 600px) {
        button {
          width: 100%;
          border-radius: 4px !important;
          margin-top: 4px !important;
          margin-bottom: 4px !important;
        }
        .btn-group {
          width: 100%;
        }
      }
    }
  }

  iframe {
    width: 100%;
    height: 800px; //TODO: set the iframe height dynamically based on content-height
    margin-top: 40px;
  }
}

.annotationsModal {
  width: 90%;
}
.editAnnotationButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.hoverRow {
  &:hover {
    cursor: pointer;
  }
}
.aggregatedAnnotations {
  .topicFilter{
    .Select-clear-zone {
      display: none
    }
  }
  .aggregatedAnnotationsSortIcons {
    &:hover {
      cursor: pointer;
    }

    .material-icons {
      font-size: 14px;
    }
  }
}
