.crawlRunNow-Modal {

  .bodyWrapper {
    padding: 20px;

    &>div:nth-child(n+2){
      margin-top: 15px;
    }
    &>label{
      margin-top: 15px;
    }
  }

  .updateCheckBox {
    margin-top: 20px;
  }

  .footerBtnBlock {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    button {
      margin-left: 15px;
    }
  }

  // Run Now Publication Date Interval Styles
  .dateWrapper{
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    label{
      flex: 1;

      &:first-child{
        margin-right: 10px;
      }
      &:last-child{
        margin-left: 10px;
      }
    }
  }

}
