.file-upload-input {

  &_wrapper {
    display: flex;
    flex-direction: column;

    &_hide {
      display: none;
    }

    &_disabled {
      svg {
        display: none;
      }
    }
  }

  &_drop-area-wrapper {
    position: relative;
    width: 100%;
    height: 54px;
    border-radius: 4px;
    border: 1px solid $light-grey;
    background-color: $white-smoke-dark;
    background-image: url('https://jurispect-web-assets.s3-us-west-2.amazonaws.com/fileUploadInputBackgroundText.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 170px;
    overflow: hidden;

    input {
      width: 100%;
      height: 0;
      padding-bottom: 74px;
      vertical-align: middle;
      cursor: pointer;
      margin-top: -22px;
      transition: background-color ease-out 0.5s;
      opacity: 0.3;
      // Disable browser styles
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      &:hover {
        opacity: 0.3;
      }
    }

    &_disabled {
      display: none;
    }

    &_error {
      border: 0.5px solid $cerise;
    }

  }

  &_uploaded-file {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-right: 5px;
    }

    svg:hover {
      cursor: pointer;
    }

  }


}
