@import 'src/app/styles/modules/colors';

.checkbox-common-component {
  &.checkbox-inline {
    padding: 0;
    margin: 0;
  }

  &__label {
    padding-left: 6px;
    color: $mine-shaft;
    font-size: 14px;
  }

  &__wrapper {
    display: flex;
    align-items: center;
  }

  input {
    display: none;
  }

  &__icon {
    &.jurispect-MuiSvgIcon-root {
      font-size: 18px;
      margin: 0;
    }

    &:hover {
      color: $hover-blue;
      text-decoration: none;
    }

    &--checked {
      @extend .checkbox-common-component__icon;
      color: $azure-radiance;
    }

    &--unchecked {
      @extend .checkbox-common-component__icon;
      color: $medium-grey;
    }
  }

  &--disabled {
    @extend .checkbox-common-component;

    .checkbox-common-component__icon {
      opacity: 0.3;

      &:hover {
        cursor: not-allowed;
        color: inherit;
      }
    }
  }
}
