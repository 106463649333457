.btn-primary {
  font-family: sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 2px;
  padding-top: 12px;
  padding-bottom: 12px;
  margin: 10px;
}


.thinBtn {

  .btn-default, .btn-danger, .btn-primary {
    min-width: 100px;
    font-family: sans-serif;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin: 0;
  }

  textarea {
    resize: vertical;
  }

  .wideButton {
    width: 50%;
    padding-left: 20px;
    text-align: start;
    margin: 10px;
  }

  .buttonIcon {
    height: 17px;
    color: white;
    float: right;
  }

  .indentBtn {
    margin: 0 20px;
  }

  .rightBtn {
    float: right;
  }

  .iconWrapper {
    width: 39px;
    height: 39px;
    background-color: #f5f5f5;
    border-radius: 4px;
    margin: 0 20px;
    transition: all 0.3s;
    padding: 8px;

    &:hover {
      cursor: pointer;
      background-color: #f1f1f1;
    }

  }
}

.smallRoundBtn {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  text-align: center;
  cursor: pointer;
  transition: all 1s;

  &.deleteBtn {
    color: white;
    margin-top: 10px;
    margin-left: 10px;
    background-color: #E57373;

    &:hover {
      color: white;
      opacity: 0.8;
      background-color: #ba3d3d;
    }
  }

  &.shrinkBtn {
    margin-left: 10px;
    margin-top: 30px;
    background-color: rgb(220,220,220);

    &:hover {
      opacity: 0.8;
      background-color: rgb(255,255,255);
    }
  }
}

