.switch {

  &_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &_label {
    &_disable {
      opacity: 0.35;
    }

    &_error {
      color: $cerise;
    }
  }

  &_input_wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }

  &_toggle {
    &_wrapper {
      position: relative;
      display: inline-block;
      height: 14px;
      width: 32px;
      margin-bottom: 0;

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked + .switch_toggle_input:before {
          background-color: $royal-blue;
          -webkit-transform: translateX(24px);
          -ms-transform: translateX(24px);
          transform: translateX(24px);
        }
      }
    }

    &_input {
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      -webkit-transition: .6s;
      transition: .6s;
      border-radius: 7px;
      background-color: $light-grey;

      &:before {
        position: absolute;
        content: "";
        height: 20px;
        width: 20px;
        bottom: -3px;
        left: -6px;
        background-color: $light-white;
        box-shadow: 0 1px 1px 0 $shadow;
        -webkit-transition: .6s;
        transition: .6s;
        border-radius: 50%;
      }

      &_disable {
        opacity: 0.35;
      }

      &_error {
        border: 0.5px solid $cerise;
      }
    }

  }
}
