@import "src/admin/styles/modules/colors";

.btn-new-element {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  margin-bottom: 10px;

  &_exist {
    &_btn {
      flex: 1;
      &_active {
        background-color: $alice-blue !important;
        &_error {
          background-color: $cerise-light !important;
        }
      }
    }

    &_icon {
      cursor: pointer;

      &_active {
        color: $cerise;

        &:hover {
          color: $bright-red;
        }
      }

      &_disabled {
        color: $gainsboro;
      }
    }
  }

  &_disabled {
    svg {
      display: none;
    }
  }

  &_error {
    button {
      border: 1px solid $cerise;
      color: $cerise;
    }
  }

  &_new {
    &_disabled {
      display: none;
    }

    &_error {
      color: $cerise;
    }
  }
}
