.bodyWrapper {
  padding: 20px;

  input,
  textarea {
    font-size: 13px;
  }

  label:not(:first-child) {
    margin-top: 10px;
  }
}

.documentDetails-Modal {
  &__title {
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }
  &__content {
    position: relative;
  }
}

.footerBtnBlock {
  display: flex;
  justify-content: flex-end;
  margin-top: 25px;

  &__action {
    margin-left: 15px;
  }
}
