@use '@compliance.ai/styles/dist/variables';

.app-left-bar {
  width: 7rem;
  display: flex;
  border-top: variables.$border-base;
  background-color: variables.$primary-color-3;
  flex-direction: column;

  &__link {
    padding: variables.$spacing-s variables.$spacing-xs;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 7rem;
    color: variables.$secondary-color-11;
    text-align: center;
    line-height: 1;
    outline: none;

    &:hover {
      text-decoration: none;
      color: #{variables.$primary-color-2}90;
    }

    &:focus {
      text-decoration: none;
      color: variables.$secondary-color-11;
    }

    svg {
      width: 25px;
      height: 25px;
    }

    &--is-active {
      background-color: variables.$primary-color-1;
      color: variables.$primary-color-2;

      &:hover {
        color: variables.$primary-color-2;
        background-color: variables.$secondary-color-1;
      }

      &:focus {
        color: variables.$primary-color-2;
      }
    }
  }
}
