@use '@compliance.ai/styles/dist/variables';

.app-header {
  background-color: variables.$primary-color-3;
  padding: variables.$spacing-xl;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo-link:hover {
    text-decoration: none;
  }

  &__logo {
    width: 15rem;
    height: auto;
  }

  &__logo-wrapper {
    display: flex;
    align-items: center;
  }

  &__logo-separator {
    width: variables.$border-width-s;
    height: 3rem;
    margin: 0 variables.$spacing-l;
    background-color: variables.$primary-color-2;
  }

  &__username {
    color: variables.$primary-color-2;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  &__dropdown {
    padding: unset;
  }

  &__arrow.app-header__arrow {
    font-size: variables.$font-size-xl;
    cursor: pointer;
    transform: rotate(90deg);
  }

  &__list {
    display: flex;
    flex-direction: column;
    padding: unset;
    margin: unset;

    & > * {
      cursor: pointer;
      padding: variables.$spacing-m;
      text-decoration: none;
      display: flex;
      gap: variables.$spacing-xs;
      align-items: center;

      &:hover {
        background-color: variables.$secondary-color-8;
        text-decoration: none;
      }
    }
  }
}
