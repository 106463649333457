.custom-tooltip {
  position: relative;
  display: inline-block;
  width: 100%;

  &_container {
    position: absolute;
    z-index: 120;
    display: none;
    margin-top: 2px;
    max-width: 500px;
    min-width: 120px;
    background-color: $white;
    border: 1px solid $very-light-grey;
    border-radius: 5px;
    box-shadow: 0 0 5px $very-light-grey;

    &_table {
      position:fixed;
    }

    &--visible {
      display: block;
    }
  }

  &_inner {
    word-wrap: break-word;
    padding: 8px;
    white-space: normal;
  }

  &_children {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_arrow {
    position: absolute;
    height: 20px;
    width: 20px;
    transform: translateX(-50%);

    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: -1px;
      width: 0;
      height: 0;
      transform: translateX(-50%);
      border-bottom: solid 10px $very-light-grey;
      border-left: solid 10px transparent;
      border-right: solid 10px transparent;
    }

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      width: 0;
      height: 0;
      transform: translateX(-50%);
      border-bottom: solid 9px $white;
      border-left: solid 9px transparent;
      border-right: solid 9px transparent;
    }
  }

  .info-icon {
    position: absolute;
  }

  .info-icon-doc {
    position: absolute;
    right: 10px;
    color: white;
    bottom: 15px;
  }
}
