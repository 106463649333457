@import 'src/app/styles/modules/colors';
@import 'src/app/styles/modules/mixins';

.auth-form {
  &__outer-container {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  &__container {
    @include center-alignment-for-children();

    padding: 60px 60px;
    background-color: $compliance_ai_bkgrd;
    flex: 1;
  }
}
