.crawlDetails {
  &_closeIcon {
    height: 60px;
    width: 60px;
    background-color: lightgrey;
    border: none;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  }

  &_header {
    padding: 10px 20px 0 20px;
    background-color: $white-smoke;
    box-shadow: 0 1px 0 0 $gainsboro;
    display: flex;
    justify-content: space-between;
  }

  &_statuses {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  &_statusesDates {
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 20px;
  }

  &_statusesSelect {
    padding-right: 45px;
    padding-left: 18px;
    border-left: 1px solid $gray-light;
  }

  &_navigationTabs {
    span {
      font-family: 'Source Sans Pro', sans-serif;
      font-weight: 600;
    }
  }

  &_wrapper {
    display: flex;
    padding: 40px 50px;

    p, span, div {
      font-family: 'Source Sans Pro', sans-serif;
    }
  }
}
