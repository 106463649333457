.stairs-spinner {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.stairs-spinner div {
  display: inline-block;
  position: absolute;
  left: 6px;
  width: 13px;
  background: #65B0C9;
  animation: stairs-spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.stairs-spinner div:nth-child(1) {
  left: 6px;
  animation-delay: -0.24s;
}
.stairs-spinner div:nth-child(2) {
  left: 26px;
  animation-delay: -0.12s;
}
.stairs-spinner div:nth-child(3) {
  left: 45px;
  animation-delay: 0;
}
@keyframes stairs-spinner {
  0% {
    top: 6px;
    height: 51px;
  }
  50%, 100% {
    top: 19px;
    height: 26px;
  }
}
