@import "src/admin/styles/modules/colors";

.accordion {

  &_button {
    cursor: pointer;
    padding: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    transition: 0.4s;
    background-color: $light-white;
    gap: 10px;

    & > div {
      justify-content: space-between;
      align-items: center;
      display: flex;
      flex-direction: row;
      flex: 1;
    }

    &_block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      &_symbol {
        &:after {
          content: '\02795';
          font-size: 16px;
          color: $black;
          // margin-left: 5px;
        }

        &_open:after{
          content: "\2796";
        }
      }
    }
  }

  &_panel {
    display: block;

    &_inactive {
      display: none;
    }
  }
}
