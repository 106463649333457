@use '@compliance.ai/styles/dist/variables';
.document-list {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  &_details {
    display: flex;
    padding: 20px;
    max-width: 500px;
    flex: 1;

    &_items {
      flex: 1;

      &_title {
        display: flex;
        justify-content: space-between;

        &_buttons {
          display: flex;
          flex-direction: row;
          gap: 15px;
        }
      }

      &_list {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        padding: 15px 0;
        gap: 10px;

        &_element {
          border-radius: 4px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1;
          padding: 13px;

          &_hash-text,
          p {
            word-break: break-all;
            text-align: justify;
          }

          &_active {
            border: variables.$border-base;
            background-color: $light-white;
          }

          &_master_version {
            border: 2px solid $bright-red;
            background-color: $light-grey;
          }

          &_disabled {
            border: 1px solid $gainsboro;
            background-color: $white-smoke;
            cursor: pointer;

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  .document-text {
    display: flex;
    flex: 2;
    min-height: 100vh;
  }
}
