@import "./screens";
@import "src/app/styles/modules/colors";

@mixin hide-on-mobile-device {
  @media screen and (max-width: $small) {
    display: none;
  }
}

@mixin right-panel-task-property-label {
  font-size: 10px;
  color: $medium-grey;
  margin: 2px 0;
}

@mixin right-panel-selected-value-input {
  color: $black;
  margin-bottom: 15px;
  padding: 8px;
  width: 50%;
  border: none;
  background-color: $white;
}

@mixin center-alignment-for-children {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
