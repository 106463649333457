@use '@compliance.ai/styles/dist/variables';

.custom-component {
  display: flex;
  align-items: center;

  label {
    display: flex;
    flex: 1;
    margin-left: 0;
    justify-content: space-between;
  }

  .infoTooltipIcon {
    color: variables.$secondary-color-10;
    margin-left: variables.$spacing-xl;
    font-size: variables.$font-size-l;
  }
}
