.confirmationModal {
  &_body {
    padding: 15px;

    .confirmationModalText {
      display: block;
      margin-bottom: 15px;
    }

    .buttonBlock {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 15px;
        height: 40px;
      }
    }

    .textarea_input {
      min-height: 300px;
    }
  }
}

