.document-info {
  display: flex;
  justify-content: space-between;
  flex-direction: row;

  .document-details {
    display: flex;
    flex: 1;

    &_tabs {
      display: flex;
      flex-direction: column;
      background-color: $white;
      padding: 20px;
      flex: 1;

      &_tabs-header {
        background-color: $white;

        li {
          padding: 7px;

          span {
            @extend .typography_p;
          }
        }
      }

      &_title {
        display: flex;
        justify-content: space-between;

        &_id {
          display: flex;
          justify-content: flex-start;
        }

        &_doc-id {
          margin-left: 7px;
        }
      }

      &_button-block {
        display: flex;
        flex-direction: row;
        gap: 15px;
      }
    }

    &_fields {
      display: flex;
      flex-wrap: wrap;
      padding: 15px 0;
      flex: 6;
      gap: 15px;

      .input_2 {
        flex: 6;
        min-width: 100%;
      }

      .input_1 {
        flex: 3;
        min-width: 40%;
      }

      .input_3 {
        flex: 2;
        min-width: 27%;
      }

      &_status-select {
        .custom-select__control span {
          color: $white;
        }

        .custom-select__indicator-separator {
          display: block;
        }

        .custom-select__indicator {
          color: $white;
        }

        .custom-select__single-value {
          color: $white;
        }

        .custom-select__single-value--is-disabled {
          color: $white;
          opacity: 0.5;
        }

        &_blue .custom-select__control {
          background-color: $navy-blue-new;
        }

        &_gray .custom-select__control {
          background-color: $middle-shadow;
        }

        &_green .custom-select__control {
          background-color: $olive;
        }

        &_light-red .custom-select__control {
          background-color: $chestnut-rose;
        }

        &_purple .custom-select__control {
          background-color: $medium-orchid;
        }

        &_pink .custom-select__control {
          background-color: $persian-pink;
        }

        &_brown .custom-select__control {
          background-color: $copper-rust;
        }

        &_red .custom-select__control {
          background-color: $cerise;
        }

        &_yellow .custom-select__control {
          background-color: $yellow-sea;
        }
      }

      .language-wrap {
        display: flex;
        justify-content: space-between;
        gap: 10px;
      }

      &_citations {
        background: transparent;
      }
    }
  }

  .document-text {
    display: flex;
    flex: 2;
    white-space: pre-wrap;
  }
}
