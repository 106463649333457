@use '@compliance.ai/styles/dist/variables';

.document-export-modal {
  &__container {
    width: 50rem;
  }

  &-child__container {
    padding: 0;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: variables.$spacing-l;
    border-bottom: variables.$base-border-size solid variables.$secondary-color-8;
  }

  &__body {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin: variables.$spacing-xl;
    gap: variables.$spacing-m;

    &-section-block {
      width: 33.33%;
    }
  }

  &__selectors {
    display: flex;
    align-items: center;
    gap: variables.$spacing-l;
  }

  &__columns {
    display: flex;
    margin-left: variables.$spacing-xs;
  }

  &__checkbox {
    padding: variables.$spacing-xxs;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: variables.$spacing-l;
    padding: variables.$spacing-l variables.$spacing-xl;
    border-top: variables.$border-base;
  }
}
