@import "src/app/styles/modules/colors";

.button {

  // ** Tertiary button styles **
  &-tertiary {
    color: $hover-blue;
    cursor: pointer;
    background: none;
    border: none;
    outline: none;
  }
  &-tertiary:after {
    content: '';
    width: 0px;
    height: 1px;
    display: block;
    background: $hover-blue;
    transition: 300ms;
  }
  &-tertiary:hover:after {
    width: 100%;
  }

  &-tertiary-bold {
    @extend .button-tertiary;
    font-weight: bold;
  }

  // ** Cancel button styles **
  &-cancel {
    width: 100%;
    max-width: 150px;
    background-color: white;
    border: none;
    border-radius: 0px;
    color: $royal-blue;
    padding: .75rem 3.5rem;
    align-self: center;
    margin: 0 1rem;
    outline:0;
    cursor: pointer;
  }
  &-cancel:hover { color: red; }

   // ** Apply button styles **
  &-apply {
    width: 100%;
    max-width: 150px;
    background-color: $royal-blue;
    border: solid 1px $royal-blue;
    border-radius: 0;
    color: white;
    padding: .75rem 3.5rem;
    align-self: center;
    margin: 0 1rem;
  }
  &-apply:hover {
    background-color: $science-blue;
  }
  &-apply:disabled {
    color: $light-grey;
    background-color: $gray;
    border-color: $gray;
  }
  &-apply:focus {
    outline: 0;
  }

  // ** Delete button styles **
  &-delete {
    background: none;
    border: none;
    padding: 0 10px 8px 10px;
    font-size: 12px;
    color: rgba(255, 255, 255, .5);
  }
}
