@use '@compliance.ai/styles/dist/variables';

.crawl-status-select {
  &__trigger-content {
    min-width: 11.25rem;
    height: 2.75rem;
  }

  &__trigger-button,
  &__trigger-content > .CAI-dropdown__trigger-content-wrapper {
    height: 100%;
    width: 100%;
  }

  &__trigger-button.crawl-status-select__trigger-button {
    padding: 0;
    border-radius: variables.$border-radius-m;

    .MuiButton-startIcon {
      margin: 0 variables.$spacing-xs;
    }

    .MuiButton-endIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      height: 2.75rem;
      margin-right: 0;
      border-left: variables.$border-width-xs solid variables.$primary-color-2;
      svg {
        transform: rotate(90deg);
      }
    }
  }

  &__trigger-button-label {
    overflow: hidden;
    text-overflow: ellipsis;
    flex: 1;
    text-align: left;
    white-space: nowrap;
  }

  &__dropdown-content {
    padding: 0;
  }

  &__state-options-list {
    width: 20rem;
  }
}
