@use '@compliance.ai/styles/dist/variables';

.auth-check {
  &__loader-container {
    position: fixed;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__loader-background {
    background-color: variables.$primary-color-2;
  }
}
