.typography {
  font-family: "Source Sans Pro", "SourceSansPro", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 400;

  //fonts
  &_h0 {
    font-size: 45px;
  }

  &_h1 {
    font-size: 36px;
  }

  &_h2 {
    font-size: 32px;
  }

  &_h3 {
    font-size: 28px;
  }

  &_h4 {
    font-size: 24px;
  }

  &_h5 {
    font-size: 20px;
  }

  &_title {
    font-size: 18px;
  }

  &_subtitle {
    font-size: 17px;
  }

  &_body {
    font-size: 16px;
  }

  &_p {
    font-size: 14px;
  }

  &_label {
    font-weight: 400;
    font-size: 13px;
  }

  &_tag {
    font-size: 12px;
  }

  // modification
  &_uppercase {
    text-transform: uppercase;
  }

  &_bold {
    font-weight: 700;
  }

  &_normal {
    font-weight: 400;
  }

  &_italic {
    font-style: italic;
  }

  &_hidden {
    display: none;
  }

  //colors
  &_blue {
    color: $navy-blue-new;
  }

  &_transparent-black {
    color: $black-transparent;
  }

  &_white {
    color: $white;
  }

  &_gray {
    color: $charcoal;
  }

  &_black {
    color: $black
  }

  &_red {
    color: $cerise;
  }

  &_yellow {
    color: $yellow-sea;
  }

  &_dark-grey {
    color: $dark-grey;
  }

  &_inherit {
    color: inherit;
  }
}
