@use '@compliance.ai/styles/dist/variables';

.bulk-edit-modal {
  &__title {
    font-size: variables.$font-size-semi-l;
  }

  &__container {
    height: 65vh;
    width: 50vw;
  }

  &__footer {
    button {
      padding: variables.$spacing-xxs variables.$spacing-s;
    }
  }

  &__content {
    position: relative;
    padding: variables.$spacing-l;
  }

  &__rules-container {
    display: flex;
    flex-direction: column;
    gap: variables.$spacing-m;
    align-items: flex-start;
  }

  &__rules-list {
    display: flex;
    flex-direction: column;
    gap: variables.$spacing-m;
    width: 100%;
  }

  &__add-rule-button-icon {
    font-size: variables.$font-size-m;
  }
}
