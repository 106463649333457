@use '@compliance.ai/styles/dist/variables';

.dry-run-reports {
  &__list {
    margin-top: variables.$spacing-xl;
    margin-bottom: variables.$spacing-s;
  }

  &__list-row {
    transition: variables.$animation-time-short;
    vertical-align: top;
    background-color: variables.$secondary-color-8;

    &:nth-child(2n + 1) {
      background-color: variables.$primary-color-2;
    }
  }

  &__list-cell {
    padding: variables.$spacing-s variables.$spacing-m;
    word-wrap: break-word;
  }
}
