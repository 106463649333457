.textarea {

  &_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_input {
    @extend .typography_p;
    border: 1px solid $very-light-grey;
    padding: 7px 10px;
    font-weight: normal;
    resize: vertical;
    // Disable browser styles
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:hover:enabled  {
      border-color: $dark-grey;
    }

    &:active:enabled, &:focus:enabled {
      border-color: $royal-blue;
    }

    &_disabled {
      @extend .typography_transparent-black;
      background-color: $white-smoke;
    }

    &_error {
      border: 0.5px solid $cerise;
    }

  }
}
