@use '@compliance.ai/styles/dist/variables';

.cai-category-merge-modal {
  &__new-category {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: variables.$spacing-m variables.$spacing-xs;
  }

  &__section-title {
    padding-top: variables.$spacing-m;
  }
}
