.logs-modal {
  &__container {
    width: 60vw;
    height: 60vh;
  }

  &__table-cell {
    width: 50%;
    max-width: 0;
  }

  &__table-outer-container {
    height: 100%;
  }

  &__table-inner-container {
    height: 100%;
  }
}
