.defaut-fallback-error-container {
  background-color: #eaeaea;
  width: 95%;
  display: flex;
  justify-content: flex-start;
  margin: 3% auto;
  padding-left: 1%;

  .error-message-text {
    margin: auto 0px;
    font-weight: bold;
    font-size: 18px;
  }

  .error-message-image {
    margin: 2% 1%;
    max-height: 30px;
  }
}