$table_width: 5000px;
$table_body_height: 500px;


.users-container {
  padding: 0 50px;
  overflow-x: scroll;
  /*
    Fixed table header technique from https://codepen.io/tjvantoll/pen/JEKIu
  */
  table {
    table-layout: fixed;
    border-collapse: collapse;

    th, td {
      padding: 5px;
      text-align: left;
    }

    thead {
      tr {
        display: block;
        position: relative;
        width: $table_width;

        .user-header {
          cursor: pointer;
          &:hover {
            color: blue;
          }

          .header-arrow {
            vertical-align: middle;
            font-size: 13px;
          }
        }
      }
    }

    tbody {
      height: $table_body_height;
      display: block;
      overflow: auto;
    }
  }

  .hide_btn {
    width: 200px;
  }
}
