$navy-blue: #112e61; // identity color (from branding guide)
$powder-blue: #65B0C9; // accent color (from branding guide)
$fern: #5cb85c;
$chestnut-rose: #d9534f;
$bright-red: #ff0000;
$black: #000;

$gray-border: #ddd;
$gray-text: #ccc;

$gray:                   #222;
$gray-light:             lighten($gray, 46.7%) !default;
$gray-lighter:           lighten($gray, 70.5%) !default;
$gray-lightest:           lighten($gray, 93.5%) !default;

// restyling colors
$prussian-blue: #023766;
$royal-blue: #2C6DDF;
$pacific-blue: #0286D4;
$navy-blue-new: #068BFF;
$navy-blue-new-opacity: lighten($navy-blue-new, 39.5%);
$alice-blue: #E6F3FF;
$cerise-light: #fcecee;
$olive: #5BB100;
$cerise: #E34059;
$medium-orchid: #A150CC;
$persian-pink: #F26FC4;
$copper-rust: #955349;
$yellow-sea: #EEA638;
$black-transparent: lighten($black, 60%);
$shadow: rgba(0,0,0,0.17);
$middle-shadow: rgba(117, 117, 117, 0.5);
$charcoal: #4A4A4A;
$very-dark-grey: #9f9f9f;
$dark-grey: #b2b2b2;
$silver: #c0c0c0;
$very-light-grey: #cbcbcb;
$light-grey: #e5e5e5;
$gainsboro: #DDDDDD;
$white-smoke-dark: #F4F4F4;
$white-smoke: #F0F0F0;
$light-white: #FAFAFA;
$white: #fff;
$gray40: #666666;
$alabaster: #f9f9f9;

