@import "src/admin/styles/modules/colors";

.expandable-btn-list {

  &_wrapper {
    display: flex;
    flex-direction: column;
  }


  &_item {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    gap: 10px;

    &_btn {
      flex: 1;

      &_active {
        background-color: $alice-blue !important;

        &_error {
          background-color: $cerise-light !important;
        }
      }
    }

    &_disabled {
      svg {
        display: none;
      }
    }

    &_close-icon {
      cursor: pointer;

      &_active {
        color: $cerise;

        &:hover {
          color: $bright-red;
        }
      }

      &_disabled {
        color: $gainsboro;
      }
    }
  }

  &_add-more {
    &_error {
      color: $cerise;
    }
  }
}
