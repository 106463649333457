@use '@compliance.ai/styles/dist/variables';

.app {
  min-height: 100%;
  position: absolute;
  min-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  &__content-container {
    display: flex;
    flex: 1;
  }

  &__content {
    flex: 1;
    overflow: auto;
    position: relative;
  }
}
