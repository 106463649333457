.sortable {

  &_wrapper {
    display: flex;
    flex-direction: column;
    @extend .typography;

  }

  &_element {

  }
}
