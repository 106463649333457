.date-picker {

  &_wrapper {
    display: flex;
    flex-direction: column;
  }

  &_field {
    height: 32px;
    background-color: $white-smoke-dark;
    border-radius: 4px;
    border: 1px solid $light-grey;
    color: $very-dark-grey;

    .react-date-picker__inputGroup {
      color: $black;
      font-weight: normal;
      padding-left: 9px;
    }

    div {
      border: none;
    }
  }

  &_calendar {
    position: fixed;
    font-size: 14px;
    color: $black;
    font-weight: normal;
    width: 229px;
    box-shadow: 0 4px 8px 0 $middle-shadow;
    border-radius: 4px;
    border: 1px solid $light-grey;
    padding: 12px;

    // header block
    & > div:nth-child(1) {
      margin-bottom: 0;
      // hide arrow steps for 1 year
      & > button:nth-child(4n+1) {
        display: none;
      }
    }

    // body block
    & > div:nth-child(2) {
      // week days
      .react-calendar__month-view__weekdays {
        abbr {
          opacity: 0.6;
          text-decoration: none;
          font-weight: normal;
          text-transform: none;
        }
      }

      // days numbers
      .react-calendar__month-view__days {
        button {
          height: 31px;
          padding: 0;

          &.react-calendar__tile--now {
            color: $white;
            background-color: $navy-blue-new;
          }

          &.react-calendar__month-view__days__day--neighboringMonth {
            color: $silver;
          }

          &.react-calendar__month-view__days__day--weekend {
            color: $black;
          }

          &:hover {
            color: $black;
            background-color: $navy-blue-new-opacity;
          }
        }

      }
    }
  }
}


.custom-date-picker {
  &_wrapper {
    position: relative;
    display: flex;
    flex-direction: column;

    .react-datepicker {
      &__input-container {
        input {
          @extend .typography_p;
          height: 38px;
          width: 100%;
          border: 1px solid $very-light-grey;
          padding: 7px 10px;
          font-weight: normal;
          // Disable browser styles
          outline: none;
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;

          &:hover:enabled {
            border-color: $dark-grey;
          }

          &:active:enabled, &:focus:enabled {
            border-color: $royal-blue;
          }

          &_disabled {
            @extend .typography_transparent-black;
            background-color: $white-smoke;
          }

          &_error {
            border: 0.5px solid $cerise;
          }
        }
      }

      &-popper {
        border: 1px solid $gainsboro;
        box-shadow: 0 10px 20px 0 $middle-shadow;
        background-color: $white;

        &_wrapper {

        }

        &_container {

          &_pickers {
            display: flex;
            position: relative;
          }

          button {
            height: 10px;
            margin: 0;
            top: 28px;
          }
        }
      }

      &__month-container {
        margin: 10px;
      }

      &__header {
        background-color: $white;
        border-bottom: none;
      }

      &__current-month {
        @extend .typography_p;
        @extend .typography_normal;
        padding: 5px;
      }

      &__day-name {
        @extend .typography_tag;
        @extend .typography_transparent-black;
        @extend .typography_normal;
        width: 29px;
        height: 29px;
        margin: 0;
        padding: 7px;
      }

      &__day {
        @extend .typography_p;
        @extend .typography_normal;
        width: 29px;
        height: 29px;
        margin: 0;
        padding: 7px;

        &--outside-month {
          @extend .typography_transparent-black;
        }

        &--selected {
          border-radius: 0;
          background-color: $navy-blue-new;
        }


        &--in-range {
          border-radius: 0;
          color: $black;
          background-color: $navy-blue-new-opacity;
        }

        &--in-selecting-range {
          border-radius: 0;
          background-color: $navy-blue-new;
        }

        &--keyboard-selected {
          border-radius: 0;
          background-color: $navy-blue-new;
        }
      }

      &__week {
        height: 25px;
        display: flex;
      }
    }

    &_error {
      .react-datepicker {
        &__input-container {
          input {
            border-color: $cerise;
          }
        }
      }
    }

    svg {
      position: absolute;
      right: 8px;
      top: 50%;
    }
  }

}
