.pagination {
  display: flex;
  align-items: center;

  div{
    padding: 5px 11px;
    margin: 0 3px;
    cursor: pointer;
  }

  .active_page{
    background-color: $navy-blue-new;
  }

  .disabled {
    color: $gainsboro;
  }

}
