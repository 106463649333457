@import "src/admin/styles/modules/colors";

.checkbox-custom {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  .checkbox-custom_checkmark {
    &:after {
      left: 3px;
      top: -1px;
      width: 5px;
      height: 10px;
      border: solid $white;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }

  &:hover input ~ .checkbox-custom_checkmark {
    background-color: $white-smoke-dark;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:disabled ~ .checkbox-custom_checkmark{
      background-color: $white-smoke-dark;
      border-color: $silver;
    }

    &:checked ~ .checkbox-custom_checkmark {
      background-color: $royal-blue;
      border-color: $royal-blue;
    }

    &:checked:disabled ~ .checkbox-custom_checkmark {
      background-color: $silver;
      border-color: $silver;
    }

    &:checked ~ .checkbox-custom_checkmark:after {
      display: block;
    }
  }

  &_checkmark {
    position: relative;
    height: 14px;
    width: 14px;
    border: 2px solid $silver;
    border-radius: 2px;
    background-color: transparent;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}
