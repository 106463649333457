.documents-table {
  width: 100%;
  table-layout: fixed;
}

.document-data {
  padding: 8px 5px;
  width: 90%;
  word-wrap: break-word;
}

.sortable {
  cursor: pointer;
}

.qa-checkbox {
  transform: scale(2);
}

.filter-container {
  border: solid 1px #d9dadb;
  border-radius: 5px;
  padding: 10px;
}

.documentAdminTool {
  .topMarginBlock {
    margin-top: 15px;
  }

  .control-label.textAlignLeft {
    text-align: left;
  }
}

.date-picker-doc-tool {
  border: 1px solid #e7e9ea;
  border-radius: 5px;
  margin: 5px;
  height: 2em;
}

.annotation-tool .training-notes {
  position: relative;
}

.training-notes .alert.alert-danger {
  position: absolute;
  top: -43px;
  right: 10px;
  opacity: 0.75;
  -webkit-transition: color 400ms, opacity 400ms; /* Safari */
  transition: color 400ms, opacity 400ms;
}

.training-notes .hide-notes {
  opacity: 0;
  display: block;
}

.obligation-sentence-comp-alt {
  margin-top: 50px;
  margin-bottom: 50px;
  border: 2px solid #1f1f1f;

  p {
    font-size: 25px;
    font-weight: bold;
    padding: 15px;
  }
}

.obligation-sentence-comp {
  margin-top: 50px;
  margin-bottom: 50px;
  border: 2px solid #1f1f1f;

  table {
    font-family: arial, sans-serif;
    display: block;
    table-layout: fixed;
    width: 100%;

    thead {
      tr {
        border-bottom: 2px solid black;
        background-color: #fbfbfb;

        th {
          padding: 10px 15px 10px 15px;
          text-align: center;
          vertical-align: middle;
        }

        //id
        th:nth-child(1) {
          width: 54px !important;
        }

        //sentence
        th:nth-child(2) {
          width: 800px !important;
        }

        //is obligation ?
        th:nth-child(3) {
          width: 150px !important;
        }

        //group
        th:nth-child(4) {
          width: 100px !important;
        }
      }
    }

    tbody {
      overflow: auto;
      height: 500px;
      display: block;

      tr {
        border-bottom: 1px solid black;

        td {
          padding: 10px 15px 10px 15px;
        }

        //id
        td:nth-child(1) {
          width: 54px !important;
          text-align: center;
        }

        //sentence
        td:nth-child(2) {
          width: 800px !important;
          vertical-align: center;

          .obligation-sentence {
            overflow: auto;
            word-wrap: break-word;
            text-align: left;
            max-height: 250px;
          }
        }

        //is obligation ? yes or no
        td:nth-child(3) {
          width: 150px !important;
          text-align: center;
        }

        //group
        td:nth-child(4) {
          width: 100px !important;
          text-align: center;

          input.obligation-group-textbox {
            width: 50px !important;
            text-align: right;
          }
        }
      }
    }
  }
}

.annotation-tool-answer {
  position: relative;
  padding: 15px;
  margin-top: 20px;
  text-align: center;
  border: 2px solid lightgray;
  border-radius: 5px;

  h3 {
    margin-top: 0px;
  }

  .correct-answer {
    color: green;
  }

  .incorrect-answer {
    color: red;
  }

  p {
    margin-top: 0px;
    text-align: left;
  }

  .btn-primary {
    padding: 12px 20px;
  }
}

.user-stats-modal {
  width: 90%;

  .modal-header {
    padding: 0px;
  }

  .modal-content {
    padding: 30px;
  }
}

.fixed-table-header-container {
  table {
    display: flex;
    flex-flow: column;
    height: 100%;
    width: 100%;
  }

  table thead {
    /* head takes the height it requires,
      and it's not scaled when table is resized */
    flex: 0 0 auto;
    width: 100%;
    border-bottom: 1px solid lightgray;
  }

  table tbody {
    /* body takes all the remaining available space */
    flex: 1 1 auto;
    display: block;
    overflow-y: scroll;
  }

  table tbody tr {
    width: 100%;
  }

  table thead,
  table tbody tr {
    display: table;
    table-layout: fixed;
  }
}

.onboarding-user-detail {
  .fixed-table-header-container {
    height: 80vh;

    thead {
      font-weight: bold;
    }

    td:nth-child(3) {
      width: 20%;
    }
  }
}

.notes-inc {
  text-align: center !important;
  min-width: 225px;
  width: 300px !important;
}

.aggregatedAnnotations-head {
  height: 47px;
  background-color: $white;
  position: sticky;
  z-index: 10;
  right: 0;
  left: 0;
  top: 0;
}

.aggregatedAnnotations-head th {
  background-color: $white;
  position: sticky;
  z-index: 10;
  left: 0;
  top: 40px;
}

.react-datepicker-popper {
  right: auto !important;
  left: auto !important;
  top: auto !important;
  bottom: auto !important;
  transform: translate3d(4px, 0px, 0px) !important;
}

.material-icons:hover {
  cursor: pointer;
}

.users-div {
  overflow: auto !important;
  width: 100% !important;
  height: 700px !important;
}

.user-header-table {
  table-layout: fixed !important;
  width: 5000px !important;
}

.email-col-left td:first-child,
.user-header-top th:first-child {
  width: 230px !important;
  max-width: 300px !important;
  min-width: 160px !important;
  position: sticky !important;
  left: 0px !important;
  z-index: 1 !important;
  background-color: $white;
}

.user-header-top th {
  width: 150px !important;
  max-width: 300px !important;
  position: sticky !important;
  top: 0 !important;
  background-color: $white !important;
}

.email-col-left td {
  width: 150px !important;
  max-width: 300px !important;
}

.user-header-top th:first-child {
  z-index: 2 !important;
}

.users-container.users-div table tbody {
  display: contents !important;
}

.users-container.users-div table thead tr {
  display: contents !important;
}

.user-header-table thead tr th {
  vertical-align: bottom;
  border-bottom: 2px solid #ddd;
}

.user-header-table tbody tr td {
  padding: 8px;
  line-height: 1.42857;
  vertical-align: top;
  border-top: 1px solid #ddd;
}

.email-col-left .qa-checkbox {
  transform: none !important;
  width: 38px !important;
}

.aggregatedAnnotations {
  position: relative;

  .topicFilter {
    .Select-clear-zone {
      display: none;
    }
  }

  .aggregatedAnnotationsSortIcons {
    &:hover {
      cursor: pointer;
    }

    .material-icons {
      font-size: 14px;
    }
  }
}

.model-validation-table {
  font-size: 20px;
  font-weight: 500;
}

.model-validation-table thead tr th,
.model-validation-table tbody tr th {
  border: 2px solid !important;
  opacity: 0.9 !important;
}

.model-validation-table tbody tr td {
  border: 2px solid !important;
  opacity: 0.9 !important;
}

.model-validation-first-col-bg {
  background-color: #d2cbcb;
}

.validation-topics {
  height: 45px !important;
  font-size: 20px !important;
  border: 2px solid !important;
  border-radius: 0px !important;
  opacity: 0.9 !important;
}

.validation-table-filter {
  font-size: 19px !important;
}

.filter-width-agency-admin {
  width: 25%;
}

//--------------Admin Tools Common Templates--------------//

.AdminToolPageTemplate {
  .filterAdminToolWrap {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    flex-wrap: wrap;
    z-index: 5;

    &_elem {
      flex: 1;
      margin: 0 10px;
      min-width: 170px;
      max-width: 240px;

      &_double {
        display: flex;
        border-radius: 4px;
        border: 1.2px solid #ccc;

        &_datePicker {
          padding-left: 5px;
          border: none;
          border-radius: 4px;
          width: 100%;
          height: 34px;
        }
      }
    }

    &_bottomGup {
      margin-bottom: 10px;
    }

    &_wideElem {
      max-width: 100%;
    }
  }

  .documentAdmitToolTable {
    margin-top: 10px;
    display: block;
    overflow-y: scroll;
    max-height: 55vh;
  }

  .adminToolList {
    font-size: 15px;

    &_oneAdminToolItem {
      padding: 10px;

      &:nth-child(2n) {
        background-color: #f9f9f9;
      }

      &:hover {
        background-color: #f5f5f5;
      }
    }

    &_ExtraDropDown {
      padding: 0;
      letter-spacing: 0;
      text-transform: none;
      font-weight: bold;
      text-align: left;
      border: none;
      font-size: 15px;
    }

    &_LineTwoValue {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      div {
        flex: 1;

        &:nth-child(2) {
          text-align: right;
        }
      }
    }

    &_ValueRightSide {
      display: flex;
      justify-content: flex-end;

      &_adminNote {
        hr {
          margin: 4px 0;
        }
      }
    }

    &_centralValue {
      text-align: center;
    }
  }

  .adminToolTableFlex {
    margin-top: 10px;
    overflow-y: scroll;
    max-height: 60vh;

    div.adminToolTableFlexRow {
      display: flex;
      justify-content: space-between;
      align-items: center;
      min-height: 40px;
      padding: 2px 0;
      border-bottom: 1px solid #ddd;

      &.flexHeader {
        background: $powder-blue;
        color: white;
        position: sticky;
        top: 0;
        font-weight: bold;

        .centeredHeaderName {
          display: flex;
          align-items: center;
        }
      }

      &:nth-child(2n) {
        background: #f9f9f9;
      }

      &:not(:first-child):hover {
        background: #f5f5f5;
        cursor: pointer;
      }

      div {
        flex: 1;
        padding: 1px 3px;
        text-overflow: ellipsis;
        overflow: hidden;

        &.flex2 {
          flex: 2;
        }
      }
    }
  }
}

.addElemAdminToolPopup {
  display: flex;
  justify-content: space-between;

  &_additionalBtn {
    padding: 5px;
    margin: 0 5px;
    background: #65b0c9;
    border: 1px solid #52a6c2;
    border-radius: 5px;
    color: #fff;

    &:hover {
      background: #419cba;
      border-color: #38869f;
    }
  }

  .filterAdminToolWrap_elem {
    margin-right: 0;
    margin-left: 0;
    min-width: 240px;
    max-width: 300px;

    &_textarea {
      resize: vertical;
    }
  }

  .interval {
    flex: 1;
  }

  .wrapperCustomRow {
    div,
    input {
      flex: 1;
    }
  }
}

.emptyFormAdminToolControl {
  border: 1px solid red;
}

.emptyFormSelectAdminToolControl {
  .Select-control {
    border: 1px solid red;
  }
}

.addLogoPopup {
  &_logoImage {
    width: 100%;
  }
}

.adminToolErrorMessage {
  margin-top: 10px;
  margin-bottom: 0;

  button {
    margin-top: 10px;
  }
}

.adminToolColumnsWrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
}

.adminToolTextFieldsWrapper {
  display: flex;
  flex-basis: 33.33%;
  justify-content: center;
}

.adminToolLeftPadding {
  min-height: 60px;
  padding: 15px 15px 15px 30px;
  border-top: 1.5px solid rgb(83, 166, 192);

  .objectName {
    margin-left: 10px;
  }
}

.stepsWrapper {
  &:nth-child(2n) {
    background-color: rgba(208, 208, 208, 0.3);
  }
}

.jurispect-MuiSvgIcon-root-1.infoIcon {
  font-size: inherit;
  color: rgb(83, 166, 192);
  cursor: pointer;
  transition: all 1s;

  &:hover {
    opacity: 0.8;
  }
}
