@import 'src/app/styles/modules/colors';

.error-alert-message {
  &__link {
    color: $white;

    &::after {
      background: $white;
    }
  }
}
