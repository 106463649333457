@use '@compliance.ai/styles/dist/variables';

.eitl-labels {
  flex: 1;
  position: relative;

  &__container {
    height: calc(100% - 4rem);
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  &__custom-actions-wrapper {
    padding-left: variables.$spacing-xs;
    display: flex;
    flex-direction: row;
    gap: variables.$spacing-m;
    align-items: center;
  }

  &__table-outer-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
  }

  &__table-inner-container {
    flex: 1;
    height: auto;
    position: relative;
  }

  &__table-toolbar {
    padding: variables.$spacing-s variables.$spacing-l 0 variables.$spacing-l;

    .CAI-table-columns-settings__container {
      & > .CAI-table-action-button__action-icon-wrapper {
        margin-left: variables.$spacing-s;
      }
    }
  }

  &__refresh-icon {
    display: inline-flex;
    margin-right: variables.$spacing-xl;
  }

  &__checkbox-cell {
    width: 5rem;
  }

  &__cell {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__button {
    margin-left: variables.$spacing-s;
  }
}
