@use '@compliance.ai/styles/dist/variables';

.document-close-btn {
  padding: 13px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: variables.$spacing-xxs;

  &__switch {
    margin: 0 0 0 variables.$spacing-xxs;
  }

  &__text {
    margin-top: variables.$spacing-xs;
  }

  button {
    height: 50px;
    width: 50px;
    border-radius: variables.$border-radius-m;
    background-color: $light-white;
    line-height: 0;

    svg {
      height: 28px;
      width: 28px;
      color: $black;
    }
  }
}
