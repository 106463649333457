@import '../../../app/styles/modules/colors';
@import '../../../app/styles/modules/mixins';

$auth-form-padding: 30px;

.login {
  &__outer-container {
    min-height: 100%;
    min-width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  &__container {
    @include center-alignment-for-children();

    padding: 60px 60px;
    background-color: $app-bkgd;
    flex: 1;
  }

  &__form-body {
    padding: $auth-form-padding;
  }

  &__form-alert {
    margin-bottom: $auth-form-padding;
  }

  &__form-labeled-field {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }

  &__form-footer {
    @include center-alignment-for-children();
    padding: 0 $auth-form-padding $auth-form-padding $auth-form-padding;
  }

  &__form-submit-button {
    width: 100%;
  }

  &__form-secondary-button {
    margin: $auth-form-padding 0 0 0;
  }

  &__form-paragraph {
    color: $black;
    margin: 0;
  }

  &__form-corporate-login-wrapper {
    width: 100%;
  }

  &__form-corporate-login-button-wrapper {
    padding: $auth-form-padding;
  }

  &__form-google-separator {
    padding: 25px;
  }
}
