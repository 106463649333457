@import 'src/app/styles/modules/colors';

$loading-overlay-level: 99;
$loading-overlay-background-level: 1;
$loading-spinner-level: 2;

.loading-overlay {
  &__container {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: $loading-overlay-level;
    cursor: not-allowed;
  }

  &__overlay-background {
    position: absolute;
    top: 0;
    left: 0;
    z-index: $loading-overlay-background-level;
    width: 100%;
    height: 100%;
    opacity: 0.3;
    background-color: $white;
  }

  &__spinner-wrapper {
    z-index: $loading-spinner-level;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none !important;
    opacity: 1 !important;
  }

  &__spinner {
    top: auto !important;
    left: auto !important;
  }
}
