.crawlActivity {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  // ----- HEADER -----

  &_header {
    display: flex;
    position: relative;
    margin: 5px 15px;

    &_multi-input {
      width: 280px;
    }

    &-counter {
      display: flex;
      align-items: center;
      margin-left: 1rem;
      color: $gray40;
    }

    &_search-modal {
      position: absolute;
      width: 100%;
      border: 1px solid $gainsboro;
      box-shadow: 0 10px 20px 0 $middle-shadow;
      background-color: $white;
      z-index: 100;
      top: 50px;
      display: flex;
      flex-direction: column;
      transition: all 1s;
      transform: translateX(-120%);

      &_visible {
        transform: translateX(0%);
      }

      .body-search-block {
        display: flex;
        padding: 15px;
        flex-wrap: wrap;

        @media screen and (max-width: 1565px) {
          justify-content: space-between;
        }

        & > label {
          flex: 1 0 24%;
          margin: 7px;

          @media screen and (min-width: 1565px) {
            max-width: 24%;
          }
        }

        &_gap-selector {
          display: flex;
          flex-direction: column;

          &_inputs-wrapper {
            display: flex;
            justify-content: space-between;
            max-width: 600px;
            gap: 7px;

            & > label {
              flex: 1;
            }
          }
        }

        &_date-btns {
          display: flex;
          justify-content: space-evenly;
          padding: 10px;
          border-bottom: 1px solid $gainsboro;

          button {
            height: 38px;
            width: 105px;
          }
        }
      }

      .footer-btn-block {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $gainsboro;
        align-items: center;
        padding: 15px;
        flex: 1;

        & > div {
          display: flex;
        }

        button {
          margin: 0 5px;
        }
      }
    }
  }

  .billboardWrapper {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    align-items: stretch;
    flex-wrap: wrap;
    line-height: 1;

    article {
      margin: 10px 5px;
      background-color: $white-smoke;

      header {
        background-color: $black;
        opacity: 0.6;
        color: $white;
        padding: 10px;
      }

      section {
        display: flex;
        justify-content: space-evenly;
        padding: 10px;

        div {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin: 10px;
          justify-content: space-between;
          text-align: center;

          .subtitle {
            margin-top: 7px;
            color: #888888;
          }
        }
      }

      &:first-child {
        background-color: $navy-blue-new;

        .typography {
          color: $white;
        }
      }
    }
  }

  .searchBar {
    display: flex;
    justify-content: space-between;
    margin: 15px;

    div {
      display: flex;
      align-items: center;
    }
  }

  .crawlActivityTable {
    tbody td:nth-child(3n) {
      border-right: 1px solid $dark-grey;
    }

    .viewDocsBtn {
      background-color: inherit;
      border: none;

      span {
        vertical-align: middle;
      }

      svg {
        color: $dark-grey;
        margin-right: 4px;
        vertical-align: middle;
      }
    }
  }
}

.extra-info-modal {
  overflow: scroll;
  padding: 10px;

  .video-container {
    display: flex;
    justify-content: center;
    align-items: center;
    video {
      width: 80%;
      object-fit: scale-down;
    }
  }

  &_paragraph-block {
    margin: 10px;
    article:first-child {
      font-weight: bold;
      font-style: italic;
    }
  }
}

.activityFooter {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: $white;

  .pagination {
    display: flex;
  }

  .limitWrapper {
    position: absolute;
    right: 2%;
    display: flex;
    align-items: center;

    .limitInput {
      width: 60px;
      margin: 0 10px;
    }
  }
}

.crawlActivity-Modal {
  &_table {
    height: 100%;
    overflow: scroll;

    table {
      thead {
        th {
          border-bottom-color: $dark-grey;
        }
      }

      tbody {
        tr {
          &:nth-child(2n - 1) {
            background-color: $white-smoke;
          }

          td {
            padding: 10px;
            max-width: 150px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }
    }

    .badge {
      span {
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }

    &_badge {
      &_default {
        background-color: $middle-shadow;
      }

      &_green {
        background-color: $olive;
      }

      &_red {
        background-color: $cerise;
      }

      &_orange {
        background-color: $yellow-sea;
      }
    }
  }

  .doc_reason_cell {
    .custom-tooltip_children {
      font-style: italic;
    }
  }
}
