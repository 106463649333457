@import 'src/app/styles/modules/colors';

.alert {
  &__container {
    display: flex;
    padding: 10px 15px;
    border-radius: 4px;
    justify-content: space-between;
    align-items: center;

    &--is-notification {
      @extend .alert__container;

      position: fixed;
      right: 60px;
      top: 60px;
      max-width: 200px;
      z-index: 10000;
    }

    &--is-inline-notification {
      @extend .alert__container;

      position: relative;
      top: unset;
      right: unset;
      max-width: unset;
    }

    &.info {
      background-color: $hover-blue;
    }

    &.error {
      background-color: $notif-red;
    }

    &.success {
      background-color: $feedback-green;
    }
  }

  &__content-wrapper {
    display: flex;
    align-items: center;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    margin-right: 15px;
  }

  &__icon {
    fill: currentColor;
    color: $white;
    font-size: 18px;
  }

  &__close-icon {
    fill: currentColor;
    color: $white;
    font-size: 14px;
    cursor: pointer;
  }

  &__title {
    color: $white;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 5px 0;
  }

  &__content {
    margin: 0;
    color: $white;
  }

  &__body {
    padding-right: 15px;
  }
}
