#token-update-menu {
  display: flex;
  flex-direction: column;
  align-items: center;

  .token-update-header {
    font-family: $font-family-sans-serif;
  }

  .token-update-campaign {
    margin: 0px 0px 10px 0px;
  }

  .token-update-buttons {
    button {
      vertical-align: middle;
    }
    button:nth-child(1) {
      margin-right: 10px;
    }

    i {
      font-size: 15px;
      vertical-align: middle;
      margin-right: 5px;
    }
  }
}

.marketing-table {
  width: 100%;

  table {
    table-layout: fixed;

    tr {
      input, textarea {
        width: 100%;
        padding: 8px 5px;
      }

      .campaign-field, .campaign-creator-field, .campaign-link-field  {
        padding: 8px 5px;
        width: 100px;
        word-wrap: break-word;

        .btn-danger {
          margin-top: 5px;
        }

        a {
          cursor: pointer;
        }
      }

      .campaign-update-field {
        display: none;

        .btn-success {
          font-family: sans-serif;
          font-size: 12px;
          letter-spacing: normal;
          white-space: nowrap;
          text-transform: uppercase;
          padding: 6px 12px;
          margin: 2px 0;
        }
      }

      .display {
        display: table-cell;
      }

      .hide {
        display: none;
      }

      .campaign-user-count {
        text-align: center;
      }
    }
  }
}
