@import "src/admin/styles/modules/colors";

.request-result {
  margin: 20px 0;

  summary {
    color: $navy-blue-new;
    cursor: pointer;

    &:hover {
      color: $navy-blue-new-opacity;
    }
  }

  div {
    padding: 5px;
  }
}
