.crawlDocumentsIngested {
  padding: 15px;
  display: flex;

  &_deprecateBtn {
    padding: 7px 10px;
    margin-right: 20px;
  }

  &_deprecationModal {
    &_body {
      padding: 20px;

      &_content{
        padding: 6px 0 20px 0;

        .switch_wrapper {
          margin-top: 10px;

          .switch_input_wrapper {
            justify-content: initial;

            label {
              margin-left: 10px;
            }
          }
        }

        .confirmationModalText{
          display: block;
          margin: 0 40px;
          text-align: center;
        }

        &_confirmInput:not(:first-child) {
          margin-top: 12px;
        }
      }

      .buttonBlock {
        border-top: 1px solid $dark-grey;
        padding: 20px 0 12px 0;
        display: flex;
        justify-content: flex-end;

        button {
          margin-left: 15px;
        }
      }

      .confirmationModalText {
        text-align: center;
      }
    }
  }
}
