.crawlMonitor {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px;
  height: 90vh;

  // ----- HEADER -----

  &_header {
    display: flex;
    justify-content: space-between;
    position: relative;

    &_multi-input {
      width: 280px;
    }

    & > div {
      display: flex;

      button {
        margin-left: 15px;
        height: 38px;
      }
    }

     &_search_container {
      display: flex;
      align-items: center;
    }

    &_counter {
      margin-left: 15px;
      color: $gray40;
    }

    &_search-modal {
      position: absolute;
      width: 100%;
      border: 1px solid $gainsboro;
      box-shadow: 0 10px 20px 0 $middle-shadow;
      background-color: $white;
      z-index: 100;
      top: 50px;
      display: flex;
      flex-direction: column;
      transition: all 1s;
      transform: translateX(-120%);

      &_visible {
        transform: translateX(0%);
      }

      .body-search-block {
        display: flex;
        padding: 15px;
        flex-wrap: wrap;

        @media screen and (max-width: 1565px) {
          justify-content: space-between;
        }

        & > label {
          flex: 1 0 24%;
          margin: 7px;

          @media screen and (min-width: 1565px) {
            max-width: 24%;
          }
        }

        &_gap-selector {
          display: flex;
          flex-direction: column;

          &_inputs-wrapper {
            display: flex;
            justify-content: space-between;

            & > label {
              width: 32%;
            }
          }
        }

        &_date-btns {
          display: flex;
          justify-content: space-evenly;
          padding: 10px;
          border-bottom: 1px solid $gainsboro;

          button {
            height: 38px;
            width: 105px;
          }
        }
      }

      .footer-btn-block {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $gainsboro;
        align-items: center;
        padding: 15px;
        flex: 1;

        & > div {
          display: flex;
        }

        button {
          margin: 0 5px;
        }
      }
    }
  }

  // ----- FOOTER -----

  .monitorFooter {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .pagination {
      display: flex;
    }

    .limitWrapper {
      position: absolute;
      right: 2%;
      display: flex;
      align-items: center;

      .limitInput {
        width: 60px;
        margin: 0 10px;
      }
    }

  }

  // ----- TABLE -----

  .crawlMonitorTable {
    height: 80vh;

    thead, tbody {
      td {
        width: auto;
        &:nth-child(1) {
          width: 15px;
          max-width: 35px;
        }
        &:nth-child(2) {
          width: 20px;
        }
        &:nth-child(3) {
          min-width: 250px;
        }
      }
    }

    &_crawlCheckbox {
      &.checked_checkbox {
        color: $navy-blue-new;
      }

      &.unchecked_checkbox {
        color: $dark-grey;
      }

      &_disabled {
        opacity: 0.5
      }
    }

    .headFilter {
      display: flex;
      align-items: center;
    }

    tr {
      &:hover {
        cursor: pointer;
      }

      td:last-child {
        border-left: 1px solid $dark-grey;
      }
    }
  }

  // -----TABLE MODAL -----

  .tableSettingModal {

    &_body {
      padding: 15px;

      .footerBtnBlock {
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
        align-items: center;

        button {
          margin-left: 15px;
        }
      }
    }

    &_contentWrapper {
      display: flex;
      padding: 15px;
      border-bottom: 1px solid $dark-grey;

      &_chooseColumn {
        flex: 1;
        border-right: 1px solid $dark-grey;
        padding: 20px;

        & > div {
          margin: 7px 0;
        }
      }

      &_chooseColumnOrder {
        flex: 1;
        padding: 15px;

        .sortableColumnElement {
          width: 100%;
          padding: 10px;
          margin: 10px 0;
          background-color: $white-smoke;
          border: 1px solid $dark-grey;
          border-radius: 5px;
        }

        .sortableColumnElement.disabled {
          opacity: 0.5
        }
      }
    }
  }

  // ------ STATUS TOOL -----

  .statusTool {
    display: flex;
    position: fixed;
    bottom: -80px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
    box-shadow: 0 4px 20px 0 $shadow;
    background: $white;
    transition: all 0.5s;

    &_visible {
      transform: translate(-50%, -170px);
    }

    &_closeBtn {
      padding: 0 24px;
      border-left: 1px solid $gainsboro;
      cursor: pointer;
      display: flex;
      align-items: center;
    }

    &_btnBlock {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 7px;
      min-width: 400px;

      &_crawlCounter {
        margin-right: 20px;
        margin-left: 7px;
      }

      button {
        width: auto;
        padding: 0 10px;
        margin: 0 7px;
      }
    }
  }
}

