.cai-categories {
  padding: 15px 20px;

  // ----- HEADER -----

  &_header {
    display: flex;
    justify-content: space-between;
    position: relative;

    &_multi-input {
      width: 280px;
    }

    & > div {
      display: flex;

      button {
        margin-left: 15px;
        height: 38px;
      }
    }

    &_search-modal {
      position: absolute;
      width: 100%;
      border: 1px solid $gainsboro;
      box-shadow: 0 10px 20px 0 $middle-shadow;
      background-color: $white;
      z-index: 100;
      top: 50px;
      display: flex;
      flex-direction: column;
      transition: all 1s;
      transform: translateX(-120%);

      &_visible {
        transform: translateX(0%);
      }

      .body-search-block {
        display: flex;
        padding: 15px;
        flex-wrap: wrap;

        @media screen and (max-width: 1565px) {
          justify-content: space-between;
        }

        & > label {
          flex: 1 0 24%;
          margin: 7px;

          @media screen and (min-width: 1565px) {
            max-width: 24%;
          }
        }

        &_gap-selector {
          display: flex;
          flex-direction: column;

          &_inputs-wrapper {
            display: flex;
            justify-content: space-between;

            & > label {
              width: 32%;
            }
          }
        }

        &_date-btns {
          display: flex;
          justify-content: space-evenly;
          padding: 10px;
          border-bottom: 1px solid $gainsboro;

          button {
            height: 38px;
            width: 105px;
          }
        }
      }

      .footer-btn-block {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $gainsboro;
        align-items: center;
        padding: 15px;
        flex: 1;

        & > div {
          display: flex;
        }

        button {
          margin: 0 5px;
        }
      }
    }
  }

    // ----- TABLE -----

  &_table {
    height: 73vh;

    &_caiCatCheckbox {
      &.checked_checkbox {
        color: $navy-blue-new;
      }

      &.unchecked_checkbox {
        color: $dark-grey;
      }
    }

    .headFilter {
      display: flex;
      align-items: center;
    }

    &_filter-status {
      &_true {
        color: $navy-blue-new;
      }
       &_false {
        color: $cerise;
      }
    }

    tr {
      &:hover {
        cursor: pointer;
      }

      td:last-child {
        border-left: 1px solid $dark-grey;
      }
    }
  }

    // -----TABLE MODAL -----

  .tableSettingModal {

    &_body {
      padding: 15px;

      .footerBtnBlock {
        display: flex;
        justify-content: flex-end;
        padding-top: 15px;
        align-items: center;

        button {
          margin-left: 15px;
        }
      }
    }

    &_contentWrapper {
      display: flex;
      padding: 15px;
      border-bottom: 1px solid $dark-grey;

      &_chooseColumn {
        flex: 1;
        border-right: 1px solid $dark-grey;
        padding: 20px;

        & > div {
          margin: 7px 0;
        }
      }

      &_chooseColumnOrder {
        flex: 1;
        padding: 15px;

        .sortableColumnElement {
          width: 100%;
          padding: 10px;
          margin: 10px 0;
          background-color: $white-smoke;
          border: 1px solid $dark-grey;
          border-radius: 5px;
        }
      }
    }
  }

  // ----- FOOTER -----

  &_footer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .pagination {
      display: flex;
    }

    .limitWrapper {
      position: absolute;
      right: 2%;
      display: flex;
      align-items: center;

      .limitInput {
        width: 60px;
        margin: 0 10px;
      }
    }
  }
}
