.docs-modal {
  &__container:not(.CAI-modal__inner-container--is-fullscreen) {
    width: 80vw;
    height: 80vh;
  }

  &__table-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__table-inner-container {
    flex: 1;
    height: unset;
  }
}
