.manage-org-teams {
  height: 100%;
  overflow: auto;
  background-color: $white;
  margin: 10px;
  padding: 10px;
  font-family: $font-family-medium;

  .back-to-all-orgs {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 20px;
    font-size: 14px;
    cursor: pointer;
  }
  .org-info {
    background-color: #272727;
    color: $white;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 5px white;
    height: 71px;
    font-size: 26px;
    padding-left: 20px;
    padding-right: 40px;
    font-weight: 200;
    .org-licenses-teams-nr {
      font-size: 16px;
    }
  }
  .org-teams-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .manage-team {
      background-color: #efefef;
      border-bottom: solid 2px #ffffff;

      .team-info-and-tool {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        align-content: center;
        height: 59px;
        margin-left: 15px;
        margin-right: 30px;
        font-size: 14px;

        .show-hide-team-info {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          align-content: center;
          margin-right: 20px;

          .team-info {
            padding-left: 10px;
          }
        }

        .delete-team {
          .material-icons {
            font-size: 18px;
            padding-left: 33px;
          }
        }
      }
      .members-container {
        .team-member {
          background-color: #f9f9f9;
          display: flex;
          flex-direction: row;
          justify-content:  space-between;
          align-items: center;
          border-top: solid 1px #efefef;
          padding-left: 46px;
          padding-right: 10px;
          height: 40px;

          .member-info {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            font-weight: 600;
            flex: 1;
            .member-name {
              flex: 1;
              padding-left: 10px;
            }
            .email-address {
              flex: 1;
            }
          }
          .member-tool {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex: 1;
            margin-left: 200px;
            margin-right: 10px;

            .modify-role {
              margin-right: 50px;
              label {
                height: 22px;
                font-weight: normal;
                &.moveUp {
                  margin-top: -7px;
                }
                input {
                  display: none;
                }
                .checked, .unchecked {
                  font-size: 18px;
                  display: none;
                  margin: 0;
                  &:hover {
                    color: #4a90e2;
                    text-decoration: none;
                  }
                }
                input + .unchecked {
                  display: inline-block;
                }
                input:checked ~ .checked {
                  display: inline-block;
                  color: #4a90e2;
                }
                input:checked ~ .unchecked {
                  display: none;
                }
              }
            }
            .remove-member {
              margin-left: 50px;
              cursor: pointer;
            }
          }

        }
      }
    }
  }
}
