@use '@compliance.ai/styles/dist/variables';

.sources {
  display: flex;
  justify-content: space-between;
  background-color: $white-smoke;
  box-shadow: 0 1px 0 0 $gainsboro;
  padding-left: 45px;

  &_tabs {
    &_head {
      padding-left: 60px;
    }

    &_tab {
    }
  }

  &_status {
    padding: 15px;
    display: flex;
    justify-content: flex-end;

    &_date-block {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-around;
      border-right: 1px solid $dark-grey;

      & > span {
        display: block;
      }
    }

    &_status-block {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;

      &_dropdown {
        padding: 0;

        &_trigger span {
          display: flex;
          gap: variables.$spacing-xs;
        }
      }

      &_option {
        display: flex;
        gap: variables.$spacing-xs;
        padding: 0 variables.$spacing-s;
      }

      &_select-wrapper {
        min-width: 180px;
        margin-bottom: 0;

        &_select {
          .custom-select__control span {
            color: $white;
          }

          .custom-select__indicator-separator {
            display: block;
          }

          .custom-select__indicator {
            color: $white;
          }

          span {
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;

            svg {
              width: 20px;
              height: 20px;
              margin-right: 5px;
            }
          }
        }

        .navigation-select {
          &_blue .custom-select__control {
            background-color: $navy-blue-new;
          }

          &_green .custom-select__control {
            background-color: $olive;
          }

          &_red .custom-select__control {
            background-color: $cerise;
          }

          &_yellow .custom-select__control {
            background-color: $yellow-sea;
          }
        }
      }

      .badge {
        justify-content: flex-start;

        span {
          display: flex;
          align-items: center;

          svg {
            width: 20px;
            height: 20px;
            margin-left: 3px;
            margin-right: 7px;
          }
        }
      }
    }

    &_icon-block {
      padding: 0 20px;

      button {
        height: 55px;
        width: 55px;
        line-height: 0;

        svg {
          height: 28px;
          width: 28px;
          color: $black;
        }
      }
    }
  }
}
