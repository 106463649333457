.select {

  &_wrapper {
    display: flex;
    flex-direction: column;
    @extend .typography;

    .custom-select {

      &__control {

        @extend .typography_p;
        border: 1px solid $very-light-grey;
        border-radius: 0;

        &--is-focused {
          box-shadow: none;
          border-color: $royal-blue;
        }

        &--is-disabled {
          @extend .typography_transparent-black;
          background-color: $white-smoke;

          .custom-select__indicators {
            display: none;
          }
        }
      }

      &__indicator-separator {
        display: none;
      }

      &__menu {
        margin: 0;
        border-radius: 0;
        border: 1px solid $very-light-grey;
        box-shadow: 0 4px 20px 0 $shadow;

        &-list {
          padding: 0;
        }
      }

      &__option {

        &--is-focused {
          background-color: $dark-grey;
        }

        &--is-selected {
          background-color: $navy-blue-new;
        }
      }

      &__multi-value {
        border-radius: 10.5px;
        background-color: $navy-blue-new;

        &__label {
          @extend .typography_white;
          @extend .typography_label;
        }

        &__remove {
          color: $white;

          &:hover {
            background: none;
            color: $cerise;
          }
        }

      }

      // Custom Menu Item Styles

      &_menu-list {
        display: flex;

        &_category {
          flex: 2;
        }

        &_sub-category {
          flex: 3;
          border-left: 1px solid $very-light-grey;
        }

        &_item {
          padding: 4px;

          &_active {
            background-color: $navy-blue-new-opacity;
            color: $navy-blue-new;
          }
        }
      }
    }

    // Error part

    &_error {
      .custom-select {
        &__control {
          border: 1px solid $cerise;

          &--is-focused {
            border-color: $bright-red;
          }

          &:hover {
            border-color: $dark-grey;
          }
        }
      }
    }
  }
}
