.crawlCatalog {
  padding: 40px 0;

  &_body-wrapper {
    display: flex;
  }

  &_body {
    flex: 5;
    padding: 0 40px;
    border-right: 1px solid $gainsboro;
  }

  &_rightPanel {
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    padding: 0 40px;

    .requiredFieldsList {
      width: 100%;

      &_title {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      ul {
        list-style-type: none;
        padding-left: 24px;
      }
    }

    .requiredSimpleFieldsList {
      width: 100%;
      margin-top: 15px;

      ul {
        list-style-type: none;
        padding-left: 0;
      }
    }

    .iconTextBlock {
      display: flex;
      align-items: center;

      span {
        margin-left: 10px;
      }
    }

    button {
      width: 100%;
      height: 40px;
      margin-top: 12px;
    }

    &_crawl-config {
      cursor: pointer;
    }

    &_action-date {
      width: 100%;
    }
  }

  &_dry-run {
    padding: 0 40px;

    &_btn-block {
      margin: 20px 0;
      display: flex;
      justify-content: flex-start;
    }

    &_file-info {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      padding: 0 15px;
    }
  }

  .custom-table {
    height: 500px;
  }
}
