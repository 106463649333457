.custom-right-tabs {
  flex-direction: row;
  display: flex;
  justify-content: space-between;

  &_list-wrapper {
    display: flex;
    flex-direction: column;
    width: 76px;
    justify-content: flex-start;
    align-items: stretch;
    background-color: $white-smoke;
    box-shadow: 1px 0 0 0 $dark-grey;
    border-left: 1px solid $dark-grey;
  }

  &_list {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    padding: 0;
    margin: 0;

    .custom-right-tab-list-item {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: space-around;
      list-style: none;
      padding: 7px 5px;
      height: 76px;
      text-align: center;
      line-height: 1;
      color: $dark-grey;

      &:hover {
        cursor: pointer;
        opacity: 0.5
      }

      &_active {
        opacity: 1;
        background-color: $royal-blue;
        color: $white;
        margin-left: -1px;
      }

      svg {
        width: 25px;
        height: 25px;
      }
    }
  }

  &_content-wrapper {
    flex: 1;
  }
}
