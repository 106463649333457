.text {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  width: fit-content;
  max-width: 100%;

  &__tooltip {
    & > * {
      width: 100%;
    }
  }

  &__tooltip-wrapper {
    width: fit-content;
    max-width: 100%;
  }
}
