@import "src/admin/styles/modules/colors";

.default-value {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  & > label {
    width: 50%;
    flex: 1;
  }

  &_null {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &_checkbox.checkbox-custom {
    align-items: flex-start;
    flex-direction: column-reverse;
    gap: 5px;
  }
}
