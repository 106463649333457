@use '@compliance.ai/styles/dist/variables';

.eitl-label-rename-modal {
  &__container {
    width: 30vw;
  }

  &__content {
    padding: variables.$spacing-l;
  }

  &__content-container {
    position: relative;
  }
}
