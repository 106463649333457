.custom-table {
  width: 100%;
  display: grid;

  &_wrapper {
    display: block;
    overflow-x: scroll;
  }

  tr {
    td {
      box-shadow: inset 0 -1px 0 0 #DDDDDD;
    }

    &:nth-child(2n) {
      background-color: $white-smoke;
    }

    &:nth-child(2n+1) {
      background-color: $white
    }

    .stickyLeftFixedCell {
      position: sticky;
      z-index: 10;
    }
  }

  thead tr {
    &:first-child {

      .stickyHeaderCell {
        position: sticky;
      }

      .stickyHeaderLeftFixedCell {
        z-index: 12;
      }

      td {
        z-index: 9;
        border-bottom: 1px solid $dark-grey;
      }
    }
  }

  td {
    max-width: 500px;
    width: 300px;
    min-width: 30px;
    padding: 10px 7px;
    background-color: inherit;
  }

  &_content-wrapper {
    display: block;
    max-height: 5.5em;
    min-height: 1em;
    overflow: hidden;
    text-overflow: ellipsis;

    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  &_spinner {
    padding: 20px;
    border: none;
    box-shadow: none;
  }

  &_caption {
  }

  &_head {
  }

  &_footer {
  }
}
