.checkbox {
  &_wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      margin-left: 7px;
    }
  }
}
