.adminRolesTable {
  tbody td, thead th {
    width: 33%;
    min-height: 40px;
  }

  &_editColumn{
    text-align: center;

    svg{
      margin: 0 5px;

      &:hover{
        opacity: 0.5;
        transition: all 0.7s;
      }
    }
  }
}

.errorSelect .Select-control{
  border: 1px solid red;
}
