.badge {
  border-radius: 4px;
  align-items: center;
  display: flex;
  justify-content: center;

  &_success {
    background-color: $olive;
  }

  &_warning {
    background-color: $yellow-sea;
  }

  &_error {
    background-color: $cerise;
  }

  &_failed {
    background-color: $bright-red;
  }

  &_unfinished {
    background-color: $copper-rust;
  }

  &_info {
    background-color: $navy-blue-new;
  }

  &_big {
    height: 32px;
    width: 214px;
  }

  &_small {

  }

  &_medium {
    height: 26px;
    width: 107px;
  }


}
