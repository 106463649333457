@import "src/admin/styles/modules/colors";

.agency-details {
  display: flex;
  padding: 40px 60px;
  flex-flow: row wrap;

  &_body {
    display: flex;
    border-right: 1px solid $gainsboro;
    flex-direction: column;
    flex: 2;

    .general-info{
      border-bottom: 1px solid $gainsboro;
      padding-right: 60px;

      &_header{
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;

        &_btn-block{
          display: flex;
          flex-direction: row;

          button {
            margin-left: 12px;
            padding: 9px 23px;
          }
        }
      }

      &_body {
        display: flex;
        flex-flow: row wrap;
        gap: 2%;

        padding: 20px 0;

        & > label{
          // override default browser input settings
          min-width: 60px;
          margin-bottom: 14px;
        }

        // body block split to 8 parts

        .input_1 {
          flex: 1 0 11%;
        }
        .input_2 {
          flex: 1.8 0 23%;
        }
        .input_4 {
          flex: 3.8 0 48%;
        }
        .input_8 {
          flex: 8 0 100%;
        }
      }
    }
  }

  &_right-bar {
    flex: 1;
    padding-left: 60px;

    .additional-info{
      &_block {
        border-bottom: 1px solid $gainsboro;
        margin-bottom: 15px;

        &>label {
          margin-top: 19px;
          margin-bottom: 15px;
        }
      }
      &_logo-image {
        max-width: 300px;
        max-height: 90px;
      }
    }
  }
}
