@import "src/app/styles/modules/colors";

.date-picker {
  font-size: 16px;
  height: 38px;
  max-width: 158px;
  border: 1px solid $silver;
  padding-left: 10px;
  width: 100%;

  &__label {
    font-size: 11px;
    color: $dark-grey;
    margin-bottom: 5px;
  }

  &__container {
    color: $dark-grey;
    display: flex;
    align-items: center;
    position: relative;

    & > div {
      width: 100%;
    }

    .react-datepicker-wrapper {
      width: 100%;
    }

    .react-datepicker__input-container {
      width: 100%;
    }
  }

  &__icon {
    font-size: 24px;
    position: absolute;
    right: 8px;
  }
}
