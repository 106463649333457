.events-cell {
  &__table-outer-container {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__table-inner-container {
    height: unset;
    flex: 1;
  }
}
