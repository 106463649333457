@use '@compliance.ai/styles/dist/variables';

.crawlDetails {
  display: flex;
  padding: 40px 60px;
  flex-flow: row wrap;

  &_body {
    display: flex;
    border-right: 1px solid $gainsboro;
    flex-direction: column;
    flex: 2;

    .generalInfo {
      border-bottom: 1px solid $gainsboro;
      padding-right: 60px;

      &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: row wrap;

        &_btnBlock {
          display: flex;
          flex-direction: row;

          button {
            margin-left: 12px;
            padding: 9px 23px;
          }
        }
      }

      &_body {
        display: flex;
        flex-flow: row wrap;
        align-items: flex-end;
        gap: 2%;

        padding: 20px 0;

        & > label {
          // override default browser input settings
          min-width: 60px;
          margin-bottom: 14px;
        }

        // body block split to 8 parts

        .input_1 {
          flex: 1 0 11%;
        }

        .input_2 {
          flex: 1.8 0 23%;
        }

        .input_4 {
          flex: 3.8 0 48%;
        }

        .input_8 {
          flex: 8 0 100%;
        }

        .singleCheckBoxOnRow {
          .switch_input_wrapper {
            justify-content: initial;
          }

          label {
            margin-left: 10px;
          }
        }
      }
    }
  }

  &_rightBar {
    flex: 1;
    padding-left: 60px;

    .additionalInfo {
      &_button {
        display: block;
        margin: variables.$spacing-s 0;
        padding: variables.$spacing-xxs variables.$spacing-xs;
      }

      &_block {
        border-bottom: 1px solid $gainsboro;
        margin-bottom: 15px;

        & > label {
          margin-top: 19px;
          margin-bottom: 15px;
        }
      }
    }

    .custom-tooltip {
      flex: 1;
    }

    .infoTooltipIcon {
      color: $very-dark-grey;
      margin-left: 20px;
      font-size: 22px;
    }
  }
}
