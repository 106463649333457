// Headings

.italic {
  font-style: italic;
}

.normal {
  font-weight: 400;
}

.bold {
  font-weight: 700;
}

.uppercase {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}
