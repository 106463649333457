.eitl-label-actions {
  &__dropdown {
    width: 10rem;
    padding: 0;
  }

  &__dropdown-button {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__list-option-label {
    white-space: nowrap;
  }
}
