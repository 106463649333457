@use '@compliance.ai/styles/dist/variables';

.crawl-navigation {
  &_tabs {
    display: flex;
    flex-direction: column;

    &_head {
      padding-left: 60px;
    }
  }

  &_status {
    padding: 15px;
    display: flex;
    justify-content: flex-end;

    &_date-block {
      padding: 0 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-around;
      border-right: variables.$border-width-xs solid variables.$secondary-color-9;

      & > span {
        display: block;
      }
    }

    &_status-block {
      padding-left: 17px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      min-width: 197px;

      &_badge {
        justify-content: flex-start;
        padding-left: 30px;
        width: 180px;
        height: 38px;

        & span {
          font-weight: 400;
        }
      }

      &_badge-placeholder {
        width: 180px;
        height: 38px;
      }
    }

    &_icon-block {
      padding: 0 variables.$spacing-xl;

      button {
        height: 55px;
        width: 55px;
        border: none;
        border-radius: variables.$border-radius-m;
        background-color: variables.$primary-color-2;
        line-height: 0;

        &:hover:not(:disabled) {
          background-color: variables.$primary-color-2;
        }

        svg {
          height: 28px;
          width: 28px;
          color: variables.$primary-color-3;
        }
      }
    }
  }
}
