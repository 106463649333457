@import 'src/app/styles/modules/mixins';
@import 'src/app/styles/modules/colors';

$auth-form-padding: 30px;

.modal {
  &__container {
    @include center-alignment-for-children();
    padding: $auth-form-padding !important; // Overrides default styles. Without it it doesn't work
  }

  &__header {
    background-color: $wild-sand !important; // Overwrites styled-component styles
  }

  &__text {
    text-align: center;
    color: $black;
  }

  &__text-top {
    @extend .modal__text;
    margin-bottom: $auth-form-padding;
  }

  &__text-bottom {
    @extend .modal__text;
    margin-top: $auth-form-padding;
  }
}
