@use '@compliance.ai/styles/dist/variables';

.bulk-edit-modal-rule {
  position: relative;
  min-height: 6rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: variables.$spacing-m;

  background-color: variables.$secondary-color-8;
  padding: variables.$spacing-m variables.$spacing-xxl variables.$spacing-m variables.$spacing-m;

  &__remove-button-icon {
    position: absolute;
    right: variables.$spacing-xs;
    top: variables.$spacing-xs;
    color: variables.$secondary-color-10;

    &:hover {
      color: variables.$primary-color-3;
      cursor: pointer;
    }
  }

  &__section {
    display: flex;
    justify-content: center;
  }

  &__label {
    width: 100%;
  }
}
