.button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: auto;
  padding: 5px 10px;

  &:focus {
    outline: none;
    opacity: .9;
    transition: all .5s;
  }

  &:hover {
    opacity: .75;
    transition: all .7s;
  }

  &_small {
    height: 32px;
  }

  &_medium {
    height: 50px;
  }

  &_large {
    height: 70px;
  }

  &_success{
    color: $white;
    background-color: $olive;
  }

  &_warning{
    color: $yellow-sea;
  }

  &_error{
    color: $cerise;
  }

  &_red{
    background-color: $cerise;
    color: $white;
  }

  &_blue{
    color: $white;
    background-color: $navy-blue-new;
  }

  &_highlighted{
    color: $navy-blue-new;
    border: 1px solid $navy-blue-new;
    background-color: $white;
  }

  &_disabled {
    opacity: 0.7;
    background-color: $dark-grey;
    color: $white;
  }

  // style if button get is error field = true
  &_is-error {
    border: 1px solid $cerise;
    color: $cerise;
  }
}
