@use '@compliance.ai/styles/dist/variables';

.crawl-activity-table {
  &__toolbar {
    padding: variables.$spacing-l variables.$spacing-s variables.$spacing-s variables.$spacing-s;
  }

  &__table-container {
    height: auto;
    min-height: 30rem;
  }

  &__column-separator {
    border-left: variables.$border-base;
  }
}
