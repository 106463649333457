.custom-radio {
  &_wrapper {
    display: block;
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:hover .custom-radio_input ~ .custom-radio_checkmark {
      border-color: $silver;
    }

    & .custom-radio_input:checked ~ .custom-radio_checkmark {
      border-color: $navy-blue-new;
    }

    & .custom-radio_input:checked ~ .custom-radio_checkmark:after {
      display: block;
    }

    & .custom-radio_checkmark:after {
      top: 3px;
      left: 3px;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: $navy-blue-new;
    }

  }

  &_input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

  }

  &_checkmark {
    position: absolute;
    top: 2px;
    left: 2px;
    height: 17px;
    width: 17px;
    border-radius: 50%;
    border: 2px solid $silver;

    &:after {
      content: "";
      position: absolute;
      display: none;
    }
  }
}
