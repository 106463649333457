@import "src/admin/styles/modules/colors";

.glassdoor {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 85vh;

  // ----- HEADER -----

  &_header {
    display: flex;
    justify-content: space-between;
    position: relative;

    &_multi-input {
      width: 280px;
    }
  }

  // ----- TABLE -----

  &_table {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid $gainsboro;

    &_left-border {
      border-left: 4px solid $royal-blue;

      & > .glassdoor_table:nth-of-type(even) {
        background-color: $alabaster;
      }
    }

    .table-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;
      justify-content: space-between;
      gap: 7px;

      &_source {
        max-width: 550px;
      }
    }

    .table-cell {
      flex: 1;
    }

    .crawler-name {
      flex: 2;
    }

    &_header {
      padding: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      transition: 0.4s;
      background-color: $gainsboro;

      &_block {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;

        &:first-child {
          flex: 16;
          margin-left: 30px;
        }

        &:nth-child(2) {
          flex: 1;
          justify-content: flex-end;
        }
      }

      // whole header
      &_source {
        padding-left: 30px;
      }

      // whole header
      &_cai-category {
        padding-left: 50px;
      }

      // left block
      .source-header-left-block {
        max-width: 550px;
      }

      // whole header
      .category-header-left-block {
        display: block;
      }
    }

    &_crawl-qa {
      flex: 1;
    }

    .source-badge {
      max-width: 50px;
      height: 32px;

      & > span.typography_p {
        font-size: 11px;
      }
    }

    .load-more {
      cursor: pointer;
    }

    .accordion-update {

      &_source {
        padding-left: 30px;
      }

      &_cai-category {
        padding-left: 50px;
      }

      .accordion_button_block {
        &:first-child {
          flex: 8;
        }

        &:nth-child(2) {
          flex: 1;
          justify-content: flex-end;
        }
      }
    }

    .crawl-qa-table {
      &_row {
        padding: 10px 7px;
        color: $gray40;

        & > div {
          flex: 1;
        }

        & > div.crawler-name {
          flex: 2;
        }
      }
    }

    .scraped-category {
      display: flex;
      flex-direction: row;
      gap: 7px;

      &_head {
        align-items: center;
      }

      &_body {
        align-items: flex-start;
        padding: 0 10px;
        margin-left: 30px;
        flex: 16;

        &_scrap {
          padding-top: 10px;
        }
      }

      &_counter {
        display: flex;
      }
    }
  }

  &_info-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 10px;
  }

  // ----- FOOTER -----

  &_footer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .pagination {
      display: flex;
    }

    .limit-wrapper {
      position: absolute;
      right: 2%;
      display: flex;
      align-items: center;

      .limit-input {
        width: 60px;
        margin: 0 10px;
      }
    }
  }
}
