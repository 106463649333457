@use '@compliance.ai/styles/dist/variables';

.crawler-qa-reports {
  &__container {
    height: 100%;
    width: 100%;
    position: absolute;
    display: flex;
    flex-direction: column;
  }

  &__table-wrapper {
    flex: 1;
    position: relative;
  }

  &__table-outer-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
  }

  &__table-inner-container {
    flex: 1;
    height: auto;
    position: relative;
  }

  &__table-toolbar {
    padding: variables.$spacing-s variables.$spacing-l 0 variables.$spacing-l;
  }

  &__table-header-cell {
    white-space: nowrap;
  }

  &__table-selected-rows-count {
    margin-left: variables.$spacing-m;
  }
}
