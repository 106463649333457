.document-categories {
  &_filter {
    display: flex;
    justify-content: space-between;

    label {
      flex: 1;
      margin: 10px;
    }
  }

  &_button-block {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
    }

    button {
      margin: 0 15px;
    }
  }

  &_edit-modal {
    &_body {
      padding: 15px;

      label {
        margin-bottom: 7px;
        margin-top: 7px;
      }
    }

    .modal>div{
      overflow: visible;
    }

    &_body-split {
      &_block {

        padding: 7px 15px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: space-between;

        label {
          flex: 1;

          &:first-child {
            margin-right: 7px;
          }

          &:last-child {
            margin-left: 7px;
          }
        }

        .split-jurisdictions {
          flex: 1;
          border: 1px solid $gainsboro;
          display: flex;
          padding: 3px;
          flex-wrap: wrap;

          &_element {
            display: flex;
            border-radius: 10.5px;
            background-color: $navy-blue-new;
            color: $white;
            cursor: pointer;
            padding: 2px 5px;
            margin: 2px 2px;
            max-height: 30px;
          }
        }
      }
    }

    &_footer {
      border-top: 1px solid $gainsboro;
      padding: 15px;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 15px;
      }
    }
  }

  &_footer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .pagination {
      display: flex;
    }

    .limitWrapper {
      position: absolute;
      right: 2%;
      display: flex;
      align-items: center;

      .limitInput {
        width: 60px;
        margin: 0 10px;
      }
    }

  }

  &_meta-table td{
    max-width: 1500px;
    width: 1000px;
  }

}
