@import 'src/app/styles/modules/colors';
@import 'src/app/styles/modules/mixins';
@import 'src/app/styles/modules/bootstrap-variables';

$form-max-width: 425px;
$form-logo-height: 25px;

.auth-form {
  max-width: $form-max-width;
  background-color: $white;
  width: 100%;
  box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.1);

  @media screen and (min-width: 500px) {
    width: $form-max-width;
  }

  &__header {
    background-color: $black;
    padding: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    @include center-alignment-for-children();
  }

  &__logo-image {
    height: $form-logo-height;
    width: auto;
  }

  &__admin-text {
    color: $white;
    padding-left: 20px;
    font-weight: bold;
    font-family: $font-family-medium;

    &::before {
      content: "";
      border-left: 1px solid $white;
      padding-right: 15px;
    }
  }
}
