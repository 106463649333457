@import "src/admin/styles/modules/colors";

.crawl-spec {
  &_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &_section {
    &_head {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    &_body {
      display: flex;

      &_fold {
        display: none;
      }


    }
  }

  &_general-info {

    &_body {
      justify-content: space-between;
      align-items: center;
      gap: 10px;
      flex-wrap: wrap;
      flex: 4;

      .input_1 {
        flex: 1;
        min-width: 21%;
        font-family: inherit;

        &_label {
          font-size: 12px;
          font-weight: 400;
          color: $charcoal;
        }

        &_select {
          &_error .custom-select__control {
            border: 0.5px solid $cerise;
          }
        }
      }
    }
  }

  &_config {
    &_body {
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-start;
      flex-wrap: wrap;
    }
  }

  &_element {
    max-width: 300px;
    min-width: 150px;
    display: flex;
    flex: 1;
    border-left: 1px solid $light-grey;
    border-right: 1px solid $light-grey;
    flex-direction: column;
    padding: 10px;

    &_hide {
      display: none;
    }

    &_head {
      border-bottom: 1px solid $light-grey;
      flex: 1;
      display: flex;
      align-items: center;
      gap: 5px;
      margin-bottom: 10px;

      &_tooltip{
        &_child{
          display: flex;

          svg {
            color: $navy-blue-new;
          }
        }
      }
    }

    &_body {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }

  &_code-filter {
    &_body {
      padding: 15px;
    }
    &_btn-block {
      display: flex;
      justify-content: flex-end;
      padding-top: 15px;
      align-items: center;
      button {
        margin-left: 15px;
      }
    }
  }
}
