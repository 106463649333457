@import '../../styles/modules/colors';

.labeled-field {
  &--inline {
    display: flex;
    align-items: center;
  }

  &__label {
    font-size: 11px;
    color: $dark-grey;
    display: block;

    &--is-capitalized {
      @extend .labeled-field__label;
      text-transform: uppercase;
    }

    &--is-right-aligned {
      @extend .labeled-field__label;
      text-align: right;
    }

    &--is-label-under-element {
      @extend .labeled-field__label;
      margin-bottom: 0;
      margin-top: 5px;
    }
  }
}
