.jurisdiction {
  &_filter {
    display: flex;
    justify-content: space-between;

    label {
      flex: 1;
      margin: 10px;
    }
  }

  &_button-block {
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
    }

    button {
      margin: 0 15px;
    }
  }

  &_edit-modal {
    &_body {
      padding: 15px;

      label {
        margin-bottom: 7px;
        margin-top: 7px;
      }
    }

    .modal>div{
      overflow: visible;
    }

    &_footer {
      border-top: 1px solid $gainsboro;
      padding: 15px;
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: 15px;
      }
    }
  }

  &_footer {
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;

    .pagination {
      display: flex;
    }

    .limitWrapper {
      position: absolute;
      right: 2%;
      display: flex;
      align-items: center;

      .limitInput {
        width: 60px;
        margin: 0 10px;
      }
    }

  }

  //&_table td{
  //  max-width: 500px;
  //  width: 500px;
  //}

}
